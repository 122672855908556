import React from "react";

import { FiEdit2 } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";

import noimageavailable from "../../assets/no-image.png";

const CMSEventCard = ({
  eventObj,
  onClickEdit,
  setEventDeleteId,
  setShowDelete,
  onClickDelete,
}) => {
  return (
    <div>
      <div className="font-bold pb-2">{eventObj?.event_name}</div>

      <div>
        {eventObj?.images_list ? (
          <img
            src={eventObj?.images_list[0]?.file_url}
            alt="event"
            className="h-48"
          />
        ) : (
          <img src={noimageavailable} alt="event" className="h-48" />
        )}
      </div>

      <div className="flex gap-x-3 pt-2">
        <div
          onClick={() => onClickEdit(eventObj)}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <FiEdit2 />
        </div>
        <div
          onClick={() => {
            setEventDeleteId(eventObj?.event_id || null);
            setShowDelete(true);
          }}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <BsTrash3 />
        </div>
      </div>
    </div>
  );
};

export default CMSEventCard;
