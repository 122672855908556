import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./common/Footer/Footer";
import Navbar from "./common/PublicNavbar/Navbar";
import NavbarHeader from "./common/PublicNavbar/NavbarHeader";

const PublicLayout = () => {
  return (
    <>
      <NavbarHeader />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default PublicLayout;
