import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import axios from "../../../api/axios";
import { BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import aboutusbodbannerimage from "../../../assets/bodexecutives.jpg";

import MembersCard from "../../../reusable-components/memberscard.component";
import BODCard from "../../../reusable-components/bodcard.component";

const AboutUsBOD = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [bodCompleteDetailsList, setBodCompleteDetailsList] = useState([]);

  const [bodChairmanDetailsTopList, setBodChairmanDetailsTopList] = useState(
    []
  );
  const [bodChairmanDetailsBottomList, setBodChairmanDetailsBottomList] =
    useState([]);

  const getBodCompleteDetailsList = async () => {
    try {
      const response = await axios.get(BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL);
      // console.log(
      //   "Board of Directors with complete details List",
      //   response.data.bodCompleteDetails
      // );
      if (response.status === 200) {
        setBodCompleteDetailsList(response.data.bodCompleteDetails);

        let top_list = [];
        let bottom_list = [];

        // response.data.bodCompleteDetails.length > 0 &&
        response.data?.bodCompleteDetails?.map((mapObj) => {
          if (parseInt(mapObj?.priority) < 3) {
            top_list.push(mapObj);
          } else {
            bottom_list.push(mapObj);
          }
        });
        setBodChairmanDetailsTopList(top_list);
        setBodChairmanDetailsBottomList(bottom_list);
      }

      response.status === 202 &&
        console.log("No board of directors found in the system.", "error");
    } catch (error) {
      console.error("getBodCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBodCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusbodbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 mb-5 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Board of Directors
          </div>

          {/* <div className="flex">
            {bodChairmanDetailsTopList
              ?.filter((filterObj) => parseInt(filterObj?.priority) === 1)
              .map((mapObj) => (
                <div>
                  <BODCard bodObj={mapObj} />
                </div>
              ))}
          </div> */}

          <div className="md:w-[80%] lg:w-[70%] mx-auto mt-10 mb-24">
            {bodChairmanDetailsTopList?.length > 0 ? (
              <div className="flex flex-col md:flex-row gap-8 md:gap-16">
                {bodChairmanDetailsTopList?.map((bodObj) => (
                  <div>
                    <BODCard bodObj={bodObj} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div>
            {bodChairmanDetailsBottomList?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {bodChairmanDetailsBottomList?.map((bodObj) => (
                  <div>
                    <BODCard bodObj={bodObj} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center text-sm text-gray-700 py-50">
                No board of directors found.
              </div>
            )}
          </div>

          {/* BOD Members Card */}
          {/* <div className="mt-12 flex flex-col">
            <MembersCard
              slno="1"
              name="Shri Man Bir Rai"
              designation="Chairman"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="2"
              name="Shri Mangal Jit Rai"
              designation="Vice Chairman"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="3"
              name="Shri Ben Kumar Gurung"
              designation="Board of Director"
              office="Chairman, Bada Samdong MPCS, West Sikkim"
            />
            <MembersCard
              slno="4"
              name="Shri Sher Bahadur Gurung"
              designation="Board of Director"
              office="Chairman, Kaluk MPCS, West Sikkim"
            />
            <MembersCard
              slno="5"
              name="Smt. Sarita Rai"
              designation="Board of Director"
              office="Chairperson, Tikpur MPCS, West Sikkim"
            />
            <MembersCard
              slno="6"
              name="Shri A.B. Rai"
              designation="Board of Director"
              office="Chairman, Sikkim Cooperative Milk Union MPCS, East Sikkim"
            />
            <MembersCard
              slno="7"
              name="Smt. Anita Mangar"
              designation="Board of Director"
              office="Chairperson, Kadamtam MPCS, East Sikkim"
            />
            <MembersCard
              slno="8"
              name="Shri Gokul Rai"
              designation="Board of Director"
              office="Chairman, Bikmat MPCS, South Sikkim"
            />
            <MembersCard
              slno="9"
              name="Shri N.B. Pradhan"
              designation="Board of Director"
              office="Chairman, Mellidara MPCS, South Sikkim"
            />
            <MembersCard
              slno="10"
              name="Shri Phur Tenzing Sherpa"
              designation="Board of Director"
              office="Chairman, Anden MPCS, West Sikkim"
            />
          </div> */}

          {/* Nominee of Government */}
          {/* <div className="text-green-500 text-sm md:text-base flex justify-start mt-6 border-b border-gray-200 pb-4">
            Nominee of Government
          </div>
          <div className="mt-12 flex flex-col">
            <MembersCard
              slno="1"
              name="Shri R.K. Gautam"
              designation="Registrar"
              office="Co-operation Department, Govt. of Sikkim"
            />
            <MembersCard
              slno="2"
              name="Secretary"
              designation=""
              office="AH, LF & VS Department, Govt. of Sikkim"
            />
            <MembersCard
              slno="3"
              name="Dr. A.B. Karki, SCS"
              designation="Managing Director"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
          </div> */}

          {/* Composition of Board of Union */}
          {/* <div className="text-green-500 text-sm md:text-base flex justify-start mt-6 border-b border-gray-200 pb-4">
            Composition of Board of Union
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700 uppercase">
                  Chairman - Elected by the member societies in the AGM for a
                  term of 5 years.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700 uppercase">
                  Secretary, Dept. of AH, LF & VS.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700 uppercase">
                  Registrar of Co-operative Societies of Sikkim.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700 uppercase">
                  Managing Director, Member Secretary - Usually on Deputation
                  from the State Government.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700 uppercase">
                  Twelve elected representatives from milk co-op societies.
                </span>
              </li>
            </ul>
          </div> */}
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsBOD;
