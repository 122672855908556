import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: 0,
  userId: 0,
  userDetails: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setIsLogin: (state) => {
      state.isLogin = 1;
    },

    setUserId: (state, action) => {
      state.userId = action.payload;
    },

    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },

    resetAllLoginVariables: (state) => {
      state.isLogin = 0;
      state.userId = 0;
      state.userDetails = {};
    },
  },
});

export const { setIsLogin, setUserId, setUserDetails, resetAllLoginVariables } =
  loginSlice.actions;

export default loginSlice.reducer;
