import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NOTICE_DETAILS_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import newicon from "../../../assets/new-icon.jpg";
import ViewTextNotice from "./view_text_notice.component";

export default function Notice() {
  const [noticeList, setNoticeList] = useState([]);

  const getNoticeDetails = async () => {
    try {
      const response = await axios.get(NOTICE_DETAILS_URL);
      response.status === 200 &&
        setNoticeList(response?.data?.noticeList[0]?.get_notice_details);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  let publishedNoticedList = noticeList?.filter(
    (noticeObj) => noticeObj?.is_publish === true
  );

  useEffect(() => {
    getNoticeDetails();
  }, []);

  return (
    <>
      <div className="my-8 text-center text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
        Notice
      </div>

      <div>
        {publishedNoticedList?.length > 0 ? (
          <>
            <div className="container mx-auto pt-6">
              {publishedNoticedList?.map((noticeObj, index) => (
                <div
                  key={index}
                  className="my-4 border-b border-gray-200 cursor-pointer"
                >
                  <div className="text-sm lg:text-base font-bold text-gray-800 ">
                    {getFormattedDateDDMMMYYYYDate(
                      noticeObj?.notice_published_startdate
                    )}{" "}
                  </div>

                  <div className="flex justify-between">
                    <div>{noticeObj?.notice_title}</div>
                    <div className="flex justify-end items-center ">
                      {index === 0 && (
                        <span>
                          <img src={newicon} className="w-9" />
                        </span>
                      )}
                      {noticeObj?.notice_type === "Both Image and PDF" ? (
                        <>
                          <div className="flex items-center gap-4">
                            <Link
                              to={`${
                                Object.values(noticeObj?.doc_details[0]?.PDF)[0]
                              }`}
                              target="_blank"
                            >
                              <p className="text-sm text-primary hover:underline   leading-tight rounded-md ">
                                Download PDF
                              </p>
                            </Link>
                            <Link
                              to={`${
                                Object.values(noticeObj?.doc_details[1]?.IMG)[0]
                              }`}
                              target="_blank"
                            >
                              <Link
                                to={`${
                                  Object.values(
                                    noticeObj?.doc_details[1]?.IMG
                                  )[0]
                                }`}
                                target="_blank"
                              >
                                <p className="text-sm text-primary hover:underline">
                                  Download Image
                                </p>
                              </Link>
                            </Link>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {noticeObj?.notice_type === "PDF" ? (
                        <>
                          <Link
                            to={`${
                              Object.values(noticeObj?.doc_details[0]?.PDF)[0]
                            }`}
                            target="_blank"
                          >
                            <span className="text-sm text-primary hover:underline ">
                              View {noticeObj?.notice_type}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                      {noticeObj?.notice_type === "Image" ? (
                        <>
                          <Link
                            to={`${
                              Object.values(noticeObj?.doc_details[0]?.IMG)[0]
                            }`}
                            target="_blank"
                          >
                            <span className="text-sm text-primary hover:underline ">
                              View {noticeObj?.notice_type}
                            </span>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                      {noticeObj?.notice_type === "Text" ? (
                        <>
                          <Link to="/view-text-notice">
                            <span className="text-sm text-primary hover:underline ">
                              View Notice
                            </span>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex justify-center text-sm text-primary py-2">
            No latest updates. Stay tuned!
          </div>
        )}
      </div>
    </>
  );
}
