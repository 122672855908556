import React, { useState, useEffect } from "react";

import axios from "../../../api/axios";
import { AWARD_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import AwardsCard from "../../../reusable-components/awardscard.component";

const AboutUsAwards = () => {
  const [awardCompleteDetailsList, setAwardCompleteDetailsList] = useState([]);

  const getAwardCompleteDetailsList = async () => {
    try {
      const response = await axios.get(AWARD_DETAILS_CONFIG_URL);
      // console.log("Award with complete details List", { response });
      response.status === 200 &&
        setAwardCompleteDetailsList(response.data.awardCompleteDetails);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAwardCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="my-16">
      <div className="flex justify-center text-primary text-xl md:text-2xl font-bold underline underline-offset-4 mb-20">
        Awards
      </div>

      <div className="mb-32">
        {awardCompleteDetailsList?.length > 0 ? (
          <div className="w-[80%] lg:w-[60%] mx-auto grid md:gap-x-20 lg:gap-x-32 gap-y-24 grid-cols-1 md:grid-cols-2">
            {awardCompleteDetailsList?.map((awardObj) => (
              <div>
                <AwardsCard awardObj={awardObj} />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-50">
            Award List to be uploaded soon!
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutUsAwards;
