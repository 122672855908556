const ROLES = require("../../api/roles_list");

const roleRoutes = {
  [ROLES["Super Admin"]]: "/sysadmin/dashboard",
  [ROLES["Content Admin"]]: "/sysadmin/dashboard",
};

module.exports = {
  roleRoutes,
};
