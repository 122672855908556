import React from "react";

import { FiEdit2 } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";

const CMSProductCard = ({
  productObj,
  onClickEdit,
  setProductDeleteId,
  setShowDelete,
  onClickDelete,
}) => {
  return (
    <div>
      <div className="font-semibold pb-2">{productObj?.product_name}</div>

      <div>
        <img
          src={productObj?.product_image_url}
          alt="product"
          className="h-48"
        />
      </div>

      <div className="flex gap-x-3 pt-2">
        <div
          onClick={() => onClickEdit(productObj)}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <FiEdit2 />
        </div>
        <div
          onClick={() => {
            setProductDeleteId(productObj?.product_id || null);
            setShowDelete(true);
          }}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <BsTrash3 />
        </div>
      </div>
    </div>
  );
};

export default CMSProductCard;
