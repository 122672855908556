import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import axios from "../../../api/axios";
import { SMU_EXECUTIVES_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import aboutusexecutivebannerimage from "../../../assets/bodexecutives.jpg";

import MembersCard from "../../../reusable-components/memberscard.component";
import SMUExecutiveCard from "../../../reusable-components/smuexecutivecard.component";

const AboutUsExecutives = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedUnitTab, setSelectedUnitTab] = useState(0);

  const activeTab =
    "p-1 md:p-2 bg-primary cursor-pointer text-white w-24 text-center text-sm md:text-base";
  const inactiveTab =
    "p-1 md:p-2 bg-white cursor-pointer w-24 text-center text-sm md:text-base";

  const [smuExecutiveCompleteDetailsList, setSmuExecutiveCompleteDetailsList] =
    useState([]);

  const [smuExecutivesGtkDetailsTopList, setSmuExecutivesGtkDetailsTopList] =
    useState([]);
  const [
    smuExecutivesGtkDetailsBottomList,
    setSmuExecutivesGtkDetailsBottomList,
  ] = useState([]);

  const [smuExecutivesJrtDetailsTopList, setSmuExecutivesJrtDetailsTopList] =
    useState([]);
  const [
    smuExecutivesJrtDetailsBottomList,
    setSmuExecutivesJrtDetailsBottomList,
  ] = useState([]);

  const getSmuExecutivesCompleteDetailsList = async () => {
    try {
      const response = await axios.get(SMU_EXECUTIVES_DETAILS_CONFIG_URL);
      // console.log(
      //   "SMU Executives with complete details List",
      //   response.data.smuExecutivesCompleteDetails
      // );
      if (response.status === 200) {
        setSmuExecutiveCompleteDetailsList(
          response.data.smuExecutivesCompleteDetails
        );

        let gtk_top_list = [];
        let gtk_bottom_list = [];

        let jrt_top_list = [];
        let jrt_bottom_list = [];

        // response.data.smuExecutivesCompleteDetails.length > 0 &&
        response.data?.smuExecutivesCompleteDetails?.map((mapObj) => {
          if (mapObj?.executive_unit_name === "Gangtok") {
            if (parseInt(mapObj?.appearance_sequence) < 2) {
              gtk_top_list.push(mapObj);
            } else {
              gtk_bottom_list.push(mapObj);
            }
          } else {
            if (parseInt(mapObj?.appearance_sequence) < 2) {
              jrt_top_list.push(mapObj);
            } else {
              jrt_bottom_list.push(mapObj);
            }
          }
        });

        // Gangtok List
        setSmuExecutivesGtkDetailsTopList(gtk_top_list);
        setSmuExecutivesGtkDetailsBottomList(gtk_bottom_list);

        // Jorethang List
        setSmuExecutivesJrtDetailsTopList(jrt_top_list);
        setSmuExecutivesJrtDetailsBottomList(jrt_bottom_list);
      }

      response.status === 202 &&
        console.log("No SMU executives found in the system.", "error");
    } catch (error) {
      console.error("getSmuExecutivesCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getSmuExecutivesCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusexecutivebannerimage}
            className="object-fill w-full h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Sikkim Milk Union Executives
          </div>

          <div className="flex mt-10 mb-5">
            <div
              onClick={() => setSelectedUnitTab(0)}
              className={`${selectedUnitTab === 0 ? activeTab : inactiveTab} `}
            >
              Gangtok
            </div>
            <div
              onClick={() => setSelectedUnitTab(1)}
              className={`${selectedUnitTab === 1 ? activeTab : inactiveTab}`}
            >
              Jorethang
            </div>
          </div>

          <>
            {selectedUnitTab === 0 && (
              <div>
                <div className="md:w-[80%] md:mx-auto mt-10 mb-24">
                  {smuExecutivesGtkDetailsTopList?.length > 0 ? (
                    <div className="md:flex justify-center">
                      {smuExecutivesGtkDetailsTopList?.map((executiveObj) => (
                        <div>
                          <SMUExecutiveCard executiveObj={executiveObj} />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div>
                  {smuExecutivesGtkDetailsBottomList?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                      {smuExecutivesGtkDetailsBottomList?.map(
                        (executiveObj) => (
                          <div>
                            <SMUExecutiveCard executiveObj={executiveObj} />
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center text-sm text-gray-700 py-50">
                      No executives found.
                    </div>
                  )}
                </div>
              </div>
            )}
          </>

          <>
            {selectedUnitTab === 1 && (
              <div>
                <div className="md:w-[80%] md:mx-auto mt-10 mb-24">
                  {smuExecutivesJrtDetailsTopList?.length > 0 ? (
                    <div className="md:flex justify-center">
                      {smuExecutivesJrtDetailsTopList?.map((executiveObj) => (
                        <div>
                          <SMUExecutiveCard executiveObj={executiveObj} />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div>
                  {smuExecutivesJrtDetailsBottomList?.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                      {smuExecutivesJrtDetailsBottomList?.map(
                        (executiveObj) => (
                          <div>
                            <SMUExecutiveCard executiveObj={executiveObj} />
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center text-sm text-gray-700 py-50">
                      No executives found.
                    </div>
                  )}
                </div>
              </div>
            )}
          </>

          {/* <div className="text-green-500 text-sm md:text-base flex justify-center mt-6 uppercase font-semibold">
            Gangtok
          </div>
          <div className="mt-12 flex flex-col">
            <MembersCard
              slno="1"
              name="Dr A.B. Karki, SCS"
              designation="Managing Director"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="2"
              name="Shri S.K. Pradhan"
              designation="General Manager (HO)"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="3"
              name="Mrs. K.D. Barfungpa"
              designation="Joint General Manager"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="4"
              name="Shri Vishal Tewari"
              designation="Joint General Manager (Plant & Quality Control)"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="5"
              name="Dr N.K. Pradhan"
              designation="Deputy Manager (Procurement & Inputs)"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
            <MembersCard
              slno="6"
              name="Shri B.N. Rai"
              designation="Senior Accounts Officer"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
          </div> */}

          {/* <div className="text-green-500 text-sm md:text-base flex justify-center mt-10 uppercase font-semibold">
            Jorethang
          </div>
          <div className="mt-12 flex flex-col">
            <MembersCard
              slno="1"
              name="Shri T.B. Subba"
              designation="Chief General Manager (RO)"
              office="The Sikkim Milk Co-operative Milk Producer's Union Limited"
            />
          </div> */}
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsExecutives;
