import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import axios from "../../../api/axios";
import { EVENT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import showToast from "../../../utilities/notification/NotificationModal";

import AddEventForm from "./addEventForm.component";
import EventList from "./eventList.component";
import Dashboard from "../../dashboard-components/dashboard.component";

const EventConfig = () => {
  const [eventCompleteDetailsList, setEventCompleteDetailsList] = useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editEventDetails, setEditEventDetails] = useState({});

  const isEdit = Object.keys(editEventDetails).length > 0 ? true : false;

  const getEventCompleteDetailsList = async () => {
    try {
      const response = await axios.get(EVENT_DETAILS_CONFIG_URL);
      // console.log("Event with complete details List", { response });
      response.status === 200 &&
        setEventCompleteDetailsList(response?.data?.eventCompleteDetails);
      response.status === 202 &&
        console.log("No events found in the system.", "error");
    } catch (error) {
      console.error("getEventCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEventCompleteDetailsList();
      setEditEventDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={"Super Admin"}>
        <ToastContainer />
        {showAddForm ? (
          <div className="flex flex-col mb-10">
            <div className="flex gap-2 items-center pb-5">
              <div className="text-primary font-semibold text-lg border-b border-primary">
                {isAdd ? <>Add Event</> : <>Update Event Details</>}
              </div>
            </div>

            <AddEventForm
              getEventCompleteDetailsList={getEventCompleteDetailsList}
              editEventDetails={editEventDetails}
              setEditEventDetails={setEditEventDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <EventList
              eventCompleteDetailsList={eventCompleteDetailsList}
              getEventCompleteDetailsList={getEventCompleteDetailsList}
              setEditEventDetails={setEditEventDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default EventConfig;
