/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";

import { TfiYoutube } from "react-icons/tfi";

import axios from "../../../api/axios";
import {
  PRODUCT_DETAILS_CONFIG_URL,
  EVENT_DETAILS_CONFIG_URL,
  NOTICE_DETAILS_URL,
  YOUTUBE_GALLERY_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";

import ViewEventDetails from "./view_event_details.component";
import YtVideoPlayer from "./ytvideo_player.component";

import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";

import homesectionimage from "../../../assets/homesectionimage.jpg";
import galleryThumbnail from "../../../assets/galleryThumbnail.jpg";
import videosThumbnail from "../../../assets/our_videos.png";
import documentaryvideo from "../../../assets/smudocumentary.mp4";

import newicon from "../../../assets/new-icon.jpg";

import ExploreProductCard from "../../../reusable-components/exploreproductcard.component";
import HomeGallery from "../../../reusable-components/homegallery.component";
import { Link } from "react-router-dom";
import ViewTextNotice from "./view_text_notice.component";

const Home = () => {
  const [productCompleteDetailsList, setProductCompleteDetailsList] = useState(
    []
  );
  const [eventCompleteDetailsList, setEventCompleteDetailsList] = useState([]);

  const [
    youtubeGalleryCompleteDetailsList,
    setYoutubeGalleryCompleteDetailsList,
  ] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const [noticeList, setNoticeList] = useState([]);

  const [selectedYoutubeVideo, setSelectedYoutubeVideo] = useState(null);

  const getProductCompleteDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_DETAILS_CONFIG_URL);
      // console.log("Product with complete details List", { response });
      response.status === 200 &&
        setProductCompleteDetailsList(response.data.productCompleteDetails);
      response.status === 202 &&
        console.log("No products found in the system.", "error");
    } catch (error) {
      console.error("getProductCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getEventCompleteDetailsList = async () => {
    try {
      const response = await axios.get(EVENT_DETAILS_CONFIG_URL);
      // console.log("Event with complete details List", { response });
      response.status === 200 &&
        setEventCompleteDetailsList(response.data.eventCompleteDetails);
      response.status === 202 &&
        console.log("No events found in the system.", "error");
    } catch (error) {
      console.error("getEventCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getNoticeDetails = async () => {
    try {
      const response = await axios.get(NOTICE_DETAILS_URL);
      response.status === 200 &&
        setNoticeList(response?.data?.noticeList[0]?.get_notice_details);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getYoutubeGalleryCompleteDetailsList = async () => {
    try {
      const response = await axios.get(YOUTUBE_GALLERY_DETAILS_CONFIG_URL);
      // console.log("Youtube Gallery with complete details List", { response });
      if (response.status === 200) {
        setYoutubeGalleryCompleteDetailsList(
          response.data.youtubeGalleryCompleteDetails
        );
      }

      response.status === 202 &&
        console.log("No youtube gallery found in the system.", "error");
    } catch (error) {
      console.error("getYoutubeGalleryCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onEventClickHandler = (eventObj) => {
    setSelectedEvent(eventObj);
    setShowEventDetails(true);
  };

  const onYtVideoClickHandler = (ytObj) => {
    // console.log("ytObj", ytObj);
    setSelectedYoutubeVideo(ytObj);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductCompleteDetailsList();
      getYoutubeGalleryCompleteDetailsList();
      getEventCompleteDetailsList();

      getNoticeDetails();

      setShowEventDetails(false);
      setSelectedEvent(null);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  //useEffect to set the first video to play inside iframe on initial render
  useEffect(() => {
    let isMounted = true;

    if (isMounted && youtubeGalleryCompleteDetailsList?.length > 0) {
      setSelectedYoutubeVideo(youtubeGalleryCompleteDetailsList[0]);
    }

    return () => {
      isMounted = false;
    };
  }, [youtubeGalleryCompleteDetailsList]);

  let publishedNoticedList = noticeList?.filter(
    (noticeObj) => noticeObj?.is_publish === true
  );

  return (
    <>
      {!showEventDetails ? (
        <div className="w-[95%] mx-auto py-10 lg:py-20">
          {/* Homepage banner section start*/}
          <div className="block">
            <div className="flex justify-center">
              <img
                src={homesectionimage}
                className="object-fill lg:object-cover h-[30vh] lg:h-[50vh] w-full"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-5 mt-10">
              <div className="col-span-1">
                <div className="flex-col px-4 shadow-xl">
                  <div className="text-[#1466A2] text-center border-b border-[#1466A2] pb-3 uppercase font-bold">
                    Notice
                  </div>

                  <div>
                    {publishedNoticedList?.length > 0 ? (
                      <>
                        <div className="container mx-auto pt-6">
                          {publishedNoticedList
                            ?.slice(0, 3)
                            ?.map((noticeObj, index) => (
                              <div
                                key={index}
                                className="my-4 border-b border-gray-300 cursor-pointer "
                              >
                                <div className="text-sm lg:text-base font-bold text-gray-800 ">
                                  {getFormattedDateDDMMMYYYYDate(
                                    noticeObj?.notice_published_startdate
                                  )}{" "}
                                </div>

                                <div className="flex justify-between text-sm lg:text-base text-primary underline underline-offset-2 pb-3">
                                  <div>{noticeObj?.notice_title}</div>
                                  <div className="flex justify-end items-center ">
                                    {index === 0 && (
                                      <span>
                                        <img src={newicon} className="w-9" />
                                      </span>
                                    )}
                                    {noticeObj?.notice_type ===
                                    "Both Image and PDF" ? (
                                      <>
                                        <div className="flex items-center gap-4 ">
                                          <Link
                                            to={`${
                                              Object.values(
                                                noticeObj?.doc_details[0]?.PDF
                                              )[0]
                                            }`}
                                            target="_blank"
                                          >
                                            <p className="text-sm text-primary hover:underline   leading-tight rounded-md ">
                                              View PDF
                                            </p>
                                          </Link>
                                          <Link
                                            to={`${
                                              Object.values(
                                                noticeObj?.doc_details[1]?.IMG
                                              )[0]
                                            }`}
                                            target="_blank"
                                          >
                                            <Link
                                              to={`${
                                                Object.values(
                                                  noticeObj?.doc_details[1]?.IMG
                                                )[0]
                                              }`}
                                              target="_blank"
                                            >
                                              <p className="text-sm text-primary hover:underline">
                                                View Image
                                              </p>
                                            </Link>
                                          </Link>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {noticeObj?.notice_type === "PDF" ? (
                                      <>
                                        <Link
                                          to={`${
                                            Object.values(
                                              noticeObj?.doc_details[0]?.PDF
                                            )[0]
                                          }`}
                                          target="_blank"
                                        >
                                          <span className="text-sm text-primary hover:underline ">
                                            View {noticeObj?.notice_type}
                                          </span>
                                        </Link>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {noticeObj?.notice_type === "Image" ? (
                                      <>
                                        <Link
                                          to={`${
                                            Object.values(
                                              noticeObj?.doc_details[0]?.IMG
                                            )[0]
                                          }`}
                                          target="_blank"
                                        >
                                          <span className="text-sm text-primary hover:underline ">
                                            View {noticeObj?.notice_type}
                                          </span>
                                        </Link>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {noticeObj?.notice_type === "Text" ? (
                                      <>
                                        <Link to="/view-text-notice">
                                          <span className="text-sm text-primary hover:underline">
                                            View Notice
                                          </span>
                                        </Link>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="w-[35%] md:w-[30%] lg:w-[25%] mx-auto pb-3">
                          <Link to="/notice">
                            <div className="p-1 lg:p-2 bg-primary flex justify-center text-white cursor-pointer text-xs lg:text-sm">
                              View All Notices
                            </div>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-center text-sm text-primary py-2">
                        No latest updates. Stay tuned!
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex-col px-4 shadow-xl">
                  <div className="text-[#1466A2] text-center border-b border-[#1466A2] pb-3 uppercase font-bold">
                    Events
                  </div>

                  <div>
                    {eventCompleteDetailsList?.length > 0 ? (
                      <>
                        <div className="mt-4 pb-4">
                          {eventCompleteDetailsList
                            ?.slice(0, 3)
                            .map((eventObj, index) => (
                              <div
                                key={index}
                                onClick={() => onEventClickHandler(eventObj)}
                                className="my-2 border-b border-gray-200 cursor-pointer"
                              >
                                <div className="text-sm lg:text-base font-bold text-gray-800">
                                  {getFormattedDateDDMMMYYYYDate(
                                    eventObj?.event_start_date
                                  )}{" "}
                                  -{" "}
                                  {getFormattedDateDDMMMYYYYDate(
                                    eventObj?.event_end_date
                                  )}
                                </div>
                                <div className="text-sm lg:text-base text-primary underline underline-offset-4  pb-3 flex items-center gap-x-5">
                                  {eventObj?.event_name}
                                  {index === 0 && (
                                    <span>
                                      <img src={newicon} className="w-9" />
                                    </span>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="w-[35%] md:w-[30%] lg:w-[25%] mx-auto pb-3">
                          <Link to="/events">
                            <div className="p-1 lg:p-2 bg-primary flex justify-center text-white cursor-pointer text-xs lg:text-sm">
                              View All Events
                            </div>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-center text-sm text-primary py-2">
                        No latest updates. Stay tuned!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Homepage banner section end*/}

          {/* Explore Our Products Section start*/}

          <div className="pt-16 lg:pt-28 flex justify-center text-[#1466A2] uppercase text-xl font-semibold">
            Explore our Products
          </div>

          <div className="pt-2 pb-10 flex justify-center text-gray-600 text-sm font-semibold">
            We bring you products from the finest dairy of Sikkim
          </div>

          <div>
            {productCompleteDetailsList?.length > 0 ? (
              <div className="w-[80%] mx-auto grid gap-x-16 gap-y-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {productCompleteDetailsList?.map((productObj) => (
                  <div>
                    <ExploreProductCard productObj={productObj} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center text-sm text-gray-700 py-50">
                No products found.
              </div>
            )}
          </div>

          {/* Explore Our Products Section end*/}

          {/* YouTube Videos Display Section start*/}

          <div className="my-8 lg:my-12">
            <div className="pt-16 pb-10 flex justify-center text-[#1466A2] uppercase text-xl font-semibold">
              Our Videos
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="flex justify-center">
                {/* <YtVideoPlayer videoSrc="https://www.youtube.com/embed/vUhqYVNBiyA?controls=1" /> */}
                <img src={videosThumbnail} />
              </div>

              <div>
                <p className="mt-10 md:mt-12 lg:mt-0 text-xs md:text-sm lg:text-base font-semibold text-gray-700">
                  Watch on YouTube/ Facebook:
                </p>
                {youtubeGalleryCompleteDetailsList?.length > 0 ? (
                  <div className="h-full flex flex-col justify-between">
                    <div className="mt-2 pb-4">
                      {youtubeGalleryCompleteDetailsList
                        // ?.slice(0, 3)
                        .map((ytObj, index) => (
                          <a href={ytObj?.youtube_video_link} target="_blank">
                            <div
                              key={index}
                              // onClick={() => onYtVideoClickHandler(ytObj)}
                              className="my-2 border border-l-primary border-l-3 cursor-pointer flex items-center justify-start p-4 rounded-sm gap-x-3 hover:shadow-xl hover:scale-105 transition-all ease-in-out duration-500"
                            >
                              <div>
                                <TfiYoutube size={24} color="red" />
                              </div>

                              <div className="text-xs md:text-sm lg:text-base text-primary hover:underline underline-offset-4 flex items-center gap-x-5">
                                {ytObj?.video_title}
                              </div>
                            </div>
                          </a>
                        ))}
                    </div>

                    {/* <div className="flex justify-start pb-3">
                      <Link to="/our-videos">
                        <div className="p-1 lg:p-2 bg-primary text-center text-white cursor-pointer text-xs lg:text-sm">
                          View All Videos
                        </div>
                      </Link>
                    </div> */}
                  </div>
                ) : (
                  <div className="flex justify-center text-sm text-primary py-2">
                    Content to be uploaded soon. Stay tuned!
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* YouTube Videos Display Section end*/}

          {/* Photo Gallery/Documentary Section start*/}

          <div className="pt-16 pb-10 flex justify-center text-[#1466A2] uppercase text-xl font-semibold">
            Photo Gallery/ Documentary
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div>
              <img src={galleryThumbnail} />
            </div>
            <div className="pt-3">
              <video controls="true" width="100%" autoPlay loop muted>
                <source src={documentaryvideo} type="video/mp4" />
              </video>
            </div>
          </div>

          {/* Photo Gallery/Documentary Section end*/}
        </div>
      ) : (
        <div className="m-8 md:m-20">
          <ViewEventDetails
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            setShowEventDetails={setShowEventDetails}
          />
        </div>
      )}
    </>
  );
};

export default Home;
