import styled from "styled-components";

export const DropzoneDiv = styled.div`
  border: 2px dashed #1466a2;
  border-radius: 5px;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  img {
    height: 200px;
  }
`;
