import React, { Fragment, useEffect } from "react";
import Error from "../../outputs/Error";

export default function InputCheckbox({
  defaultName,
  register,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  onChangeInput,
  showInput,
  defaultValue,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: required,
    pattern: pattern,
  });

  return (
    <Fragment>
      <div
        className={`${
          showInput === undefined || showInput === true
            ? "flex flex-col"
            : "hidden"
        } w-full justify-start items-start `}
      >
        <div
          className={`${
            showInput === undefined || showInput === true
              ? "flex flex-row"
              : "hidden"
          } w-full justify-start items-center lg:items-start`}
        >
          <input
            checked={defaultValue}
            onChange={async (e) => {
              if (e.target.value === "") {
                setError(defaultName, {
                  type: "required",
                  message: `${name} is required`,
                });
              } else if (pattern !== null) {
                if (!pattern.test(e.target.value)) {
                  setError(defaultName, {
                    type: "pattern",
                    message: `${name} is not valid`,
                  });
                } else {
                  clearError(defaultName);
                }
              } else if (onChangeInput !== null) {
                const res = await onChangeInput(e.target.checked);
                if (res) {
                  setError(defaultName, {
                    type: "manual",
                    message: `${name} is not available`,
                  });
                } else {
                  clearError(defaultName);
                }
              } else {
                onChange(e);
                clearError(defaultName);
              }
            }}
            {...props}
            {...rest}
            className={`${classes} border mt-1 ${
              errors[defaultName] ? "border-red-700" : "border-gray-400"
            }`}
          />
          <label className="font-medium text-left text-gray-800 pl-1 text-xs lg:text-sm">
            {name} {required && <span className="text-red-700">*</span>}
          </label>
        </div>
        {errors[defaultName] && errors[defaultName].type === "required" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is required`}
          />
        )}
        {errors[defaultName] && errors[defaultName].type === "pattern" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is not valid`}
          />
        )}
        {errors[defaultName] && errors[defaultName].type === "manual" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is not available`}
          />
        )}
      </div>
    </Fragment>
  );
}
