import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import aboutusfunctionsbannerimage from "../../../assets/aboutusfunctions.jpg";

const AboutUsFunctions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusfunctionsbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Functions
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Milk Procurement & Inputs :-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Organization of milk co-operative societies.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Arrange transportation of milk from the societies.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Training of managing committee members.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Training of secretaries / testers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Training of farmers in dairy animal management.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Arrange exposure trips of farmers to outside the state under
                  farmers’ induction programme.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Conduct co-operative development programmes for development of
                  strong co-op and for improvement of quality of milk
                  bacteriological and chemical (fat and snf).
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Cattle Development :-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Cross breeding program: Artificial Insemination, Natural
                  Service(Bull)
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Induction of cross breed cattle from outside the state by
                  coordinating between farmers and the financing bank.
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Animal Health Cover :-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Setting up veterinary first aid centers in the societies.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Arrange purchase of medicines for the first aid centers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Attend emergency call for treatment of members animals free of
                  cost.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Occasional holding animal health camps in the societies.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Co-ordinate with the department of AH & VS for vaccination of
                  animals.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Provide consultancy/ medicines to the farmers free of cost.
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Feeds & Fodder Development/Distribution:-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Feeds: Distribution of balanced milch ration to the societies
                  at subsidized rate.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Fodder Development: Distribution of fodder saplings and root
                  slips; Distribution of fodder seeds; Raising fodder nurseries
                  at societies.
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Milk Processing:-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Pasteurization of milk: For making milk safe for human
                  consumption by destroying disease producing bacteria; For
                  Enhancing shelf life of milk by destroying lactic acid
                  producing bacteria.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Production of milk products: Butter, Paneer, Churpi, Milk,
                  Cream, Ice Cream.
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Milk Marketing:-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Marketing of liquid milk in polypouches: Cow Milk(3.5% fat and
                  8.5% snf); High Cream Milk(4.0% fat and 8.5% snf); Toned
                  Milk(3.0% fat and 8.5% snf).
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Marketing of milk products: Butter, Paneer, Churpi, Milk,
                  Cream, Ice Cream.
                </span>
              </li>
            </ul>
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-start mt-6">
            Training:-
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Union staffs/board members for their capacity building:
                  Technical and Managerial.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Office bearers of societies and the farmers: Technical (Milk
                  Testing), Management of Societies, Management of Animals and
                  Awareness Camps.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsFunctions;
