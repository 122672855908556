import React, { useState } from "react";

import { EVENT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";

import CMSEventCard from "../../../reusable-components/cms-event-card/cmsEventCard.component";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function EventList({
  eventCompleteDetailsList,
  getEventCompleteDetailsList,
  setEditEventDetails,
  setShowAddForm,
  setIsAdd,
}) {
  const [showDelete, setShowDelete] = useState(false);
  const [eventDeleteId, setEventDeleteId] = useState(null);

  const onClickEdit = (event) => {
    // console.log("event received inside Edit: ", event);
    setEditEventDetails(event);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (event) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", eventDeleteId);
    try {
      let response = "";
      if (eventDeleteId) {
        response = await axios.post(`${EVENT_DETAILS_CONFIG_URL}/delete`, {
          event_id: eventDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log("Event has been deleted successfully.", "success");
        getEventCompleteDetailsList();
      } else {
        console.log("Event deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setEventDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        Events Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10 mr-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add Event</div>
          </div>
        </div>
      </div>

      <div>
        {eventCompleteDetailsList?.length > 0 ? (
          <div className="grid grid-cols-3 gap-10">
            {eventCompleteDetailsList?.map((eventObj) => (
              <div>
                <CMSEventCard
                  eventObj={eventObj}
                  onClickEdit={onClickEdit}
                  setEventDeleteId={setEventDeleteId}
                  setShowDelete={setShowDelete}
                  onClickDelete={onClickDelete}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-20">
            No events configured in the system.
          </div>
        )}
      </div>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This event will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </>
  );
}
