import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/sikkimmilklogo.png";

import SystemAdminSidebar from "../system-admin/SystemAdminSidebar";

function Sidebar({ sidebarOpen, setSidebarOpen, sidebarType }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const [showSubConfig, setShowSubConfig] = useState(true);
  const [showCMSSubConfig, setShowCMSSubConfig] = useState(true);
  const [showOrdersSubConfig, setShowOrdersSubConfig] = useState(true);

  const configClickHandler = () => {
    setShowSubConfig(!showSubConfig);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto  
        lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-44 
        lg:w-60 lg:sidebar-expanded:!w-64 2xl:!w-56 shrink-0 bg-[#1466A2] border border-r p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-44"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3  border-b pb-2 ">
          <button
            ref={trigger}
            className="lg:hidden text-slate-700 hover:text-slate-800"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="#" className="block">
            <div className="flex items-center">
              {sidebarExpanded && (
                <div className="flex flex-col gap-1">
                  <p className="text-white text-sm font-medium pl-3">
                    Hello Admin
                  </p>

                  <p className="text-white text-xs font-medium pl-3 italic">
                    Content Admin
                  </p>
                </div>

                // <img src={Logo} width="200" height="40" />
              )}
            </div>
          </NavLink>
        </div>

        {sidebarType === "Super Admin" && (
          <SystemAdminSidebar
            pathname={pathname}
            sidebarExpanded={sidebarExpanded}
            setSidebarExpanded={setSidebarExpanded}
          />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
