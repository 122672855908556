export const roundedBtn_Outlined_BorderSuccess =
  "rounded-sm px-2 py-1 bg-transparent hover:bg-green-600 text-green-600 hover:text-white border border-green-600 transition-all transition-delay-300 transition-ease-in-out";
export const full_width_button =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-full border border-gray-200 bg-slate-700 text-white";
export const button =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-max border border-gray-200 bg-primary text-white focus:outline-none focus:ring-0";
// export const button =
//   "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-max border border-gray-200 bg-primary text-white focus:outline-none focus:ring-0";
export const cancel_button =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-max border border-gray-300 bg-gray-200 text-gray-600";
export const disabled_button =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-max border border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed";
export const delete_button =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-6 py-2 text-base w-max border border-red-300 bg-red-700 text-white";
export const linkTextPrimary =
  "text-orange-300 hover:text-orange-600 transition-all ease-in-out px-2 cursor-pointer";
export const linkTextDanger =
  "text-red-600 hover:text-red-800 transition-all ease-in-out px-2 cursor-pointer";

export const outlined_btn_primary =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-4 py-2 text-base w-max text-primary border border-primary mb-4";
export const add_btn =
  "inline-flex justify-center items-center  text-xs border border-[#1466A2] bg-[#1466A2] text-white transition-all ease-in-out duration-300 rounded-sm px-5 py-2 uppercase mb-4";
// export const add_btn =
//   "inline-flex justify-center items-center transition-all duration-300 ease-in-out rounded-sm px-4 py-2 text-base w-max text-white bg-primary mb-4";
export const linkTextTernary =
  "text-ternary hover:text-secondary transition-all ease-in-out px-2 cursor-pointer";
export const linkTextSecondary =
  "text-secondary hover:text-secondary/70 transition-all ease-in-out px-2 cursor-pointer";
export const back_btn =
  "inline-flex justify-center items-center transition-all duration-300 ease-in-out border border-[#1466A2]  rounded-sm px-2 py-1 gap-2 text-medium font-semibold w-max text-secondary mb-4 hover:underline underline-offset-2";
