import React, { Fragment, useEffect, useState } from "react";

import Error from "../../outputs/Error";

import { FiEye, FiEyeOff } from "react-icons/fi";

export default function PasswordInput({
  defaultName,
  register,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  onChangeInput,
  showInput,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const { onChange, ...props } = register(defaultName, {
    required: required,
    pattern: pattern,
  });

  useEffect(() => {
    if (rest?.defaultValue !== "") {
      rest.setValue(defaultName, rest?.defaultValue, { shouldTouch: true });
    }
  }, [rest?.defaultValue]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowPassword(false);
      setShowConfirmedPassword(false);
    }
  }, []);

  const changePasswordVisibility = () => {
    var x = document.getElementById("myPasswordInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const changeConfirmedPasswordVisibility = () => {
    var y = document.getElementById("myConfirmPasswordInput");
    if (y) {
      if (y.type === "password") {
        y.type = "text";
      } else {
        y.type = "password";
      }
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Fragment>
      <div
        className={`${
          showInput === undefined || showInput === true
            ? "flex flex-col"
            : "hidden"
        } w-full my-2 justify-start items-start font-yantramanav`}
      >
        <label className="flex items-center font-medium text-left text-gray-900 pl-1 pb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-3 text-text-slate-800"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>
          {name} {required && <span className="text-red-700">*</span>}
        </label>
        {name === "Password" && (
          <div className="relative flex w-full">
            <input
              onBlur={async (e) => {
                rest?.onBlur(e);
              }}
              onChange={async (e) => {
                // setFormErrMsg("");
                if (e.target.value === "") {
                  if (required) {
                    setError(defaultName, {
                      type: "required",
                      message: `${name} is required`,
                    });
                  } else {
                    clearError(defaultName);
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                  if (onChangeInput !== null) {
                    onChangeInput(e?.target?.value);
                  }
                  // onChange(e);
                } else if (pattern !== null) {
                  if (!pattern.test(e.target.value)) {
                    clearError(defaultName);
                    clearError(`${defaultName}_onChange`);
                    setError(defaultName, {
                      type: "pattern",
                      message: `${name} is not valid`,
                    });
                  } else {
                    if (onChangeInput !== null) {
                      clearError(defaultName);
                      clearError(`${defaultName}_onChange`);
                      const res = await onChangeInput(e?.target?.value);
                      if (res) {
                        setError(`${defaultName}_onChange`, {
                          type: "manual",
                          message: `${name} is not available`,
                        });
                      } else {
                        clearError(`${defaultName}_onChange`);
                        onChange(e);
                      }
                    } else {
                      onChange(e);
                    }
                  }
                } else if (onChangeInput !== null) {
                  const res = await onChangeInput(e.target.value);
                  if (res) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is not available`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                } else {
                  clearError(defaultName);
                  clearError(`${defaultName}_onChange`);

                  onChange(e);
                }
                if (rest?.type === "number") {
                  if (parseInt(e.target.value) < parseInt(rest?.min)) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is less than expected`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                }
                if (rest?.type === "date") {
                  if (new Date(e.target.value) > new Date(rest?.max)) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is less than expected`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                }
              }}
              {...props}
              {...rest}
              autoSave="off"
              className={`${classes} border ${
                errors[defaultName]
                  ? "border-red-700"
                  : errors[`${defaultName}_onChange`]
                  ? "border-red-700"
                  : "border-gray-400"
              }`}
            />

            <div
              onClick={() => {
                setShowPassword(!showPassword);
                changePasswordVisibility();
              }}
              className="absolute right-2 top-3 cursor-pointer"
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
        )}

        {name === "Confirm Password" && (
          <div className="relative flex w-full">
            <input
              onBlur={async (e) => {
                rest?.onBlur(e);
              }}
              onChange={async (e) => {
                // setFormErrMsg("");
                if (e.target.value === "") {
                  if (required) {
                    setError(defaultName, {
                      type: "required",
                      message: `${name} is required`,
                    });
                  } else {
                    clearError(defaultName);
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                  if (onChangeInput !== null) {
                    onChangeInput(e?.target?.value);
                  }
                  // onChange(e);
                } else if (pattern !== null) {
                  if (!pattern.test(e.target.value)) {
                    clearError(defaultName);
                    clearError(`${defaultName}_onChange`);
                    setError(defaultName, {
                      type: "pattern",
                      message: `${name} is not valid`,
                    });
                  } else {
                    if (onChangeInput !== null) {
                      clearError(defaultName);
                      clearError(`${defaultName}_onChange`);
                      const res = await onChangeInput(e?.target?.value);
                      if (res) {
                        setError(`${defaultName}_onChange`, {
                          type: "manual",
                          message: `${name} is not available`,
                        });
                      } else {
                        clearError(`${defaultName}_onChange`);
                        onChange(e);
                      }
                    } else {
                      onChange(e);
                    }
                  }
                } else if (onChangeInput !== null) {
                  const res = await onChangeInput(e.target.value);
                  if (res) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is not available`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                } else {
                  clearError(defaultName);
                  clearError(`${defaultName}_onChange`);

                  onChange(e);
                }
                if (rest?.type === "number") {
                  if (parseInt(e.target.value) < parseInt(rest?.min)) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is less than expected`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                }
                if (rest?.type === "date") {
                  if (new Date(e.target.value) > new Date(rest?.max)) {
                    setError(`${defaultName}_onChange`, {
                      type: "manual",
                      message: `${name} is less than expected`,
                    });
                  } else {
                    clearError(`${defaultName}_onChange`);
                    onChange(e);
                  }
                }
              }}
              {...props}
              {...rest}
              autoSave="off"
              onWheel={numberInputOnWheelPreventChange}
              className={`${classes} border ${
                errors[defaultName]
                  ? "border-red-700"
                  : errors[`${defaultName}_onChange`]
                  ? "border-red-700"
                  : "border-gray-400"
              }`}
            />

            <div
              onClick={() => {
                setShowConfirmedPassword(!showConfirmedPassword);
                changeConfirmedPasswordVisibility();
              }}
              className="absolute right-2 top-3 cursor-pointer"
            >
              {showConfirmedPassword ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
        )}

        {errors[defaultName] && errors[defaultName].type === "required" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is required`}
          />
        )}
        {errors[defaultName] && errors[defaultName].type === "pattern" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is not valid`}
          />
        )}
        {errors[`${defaultName}_onChange`] && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${errors[`${defaultName}_onChange`].message}`}
          />
        )}
      </div>
    </Fragment>
  );
}
