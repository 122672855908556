import React from "react";

const BODCard = ({ bodObj }) => {
  return (
    <>
      <div className="shadow-lg">
        <div className="flex mb-2">
          <img src={bodObj?.bod_image_url} className="h-64 w-full" />
        </div>

        <div className="pl-2 font-bold text-sm text-black pb-4">
          {bodObj?.bod_name}
        </div>

        <div className="pl-2 font-bold text-xs text-black">
          {bodObj?.bod_designation}
        </div>

        <div className="pl-2 font-semibold text-xs text-black pb-3">
          {bodObj?.bod_society_name}
        </div>
      </div>
    </>
  );
};

export default BODCard;
