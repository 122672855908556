import React from "react";

import { FiEdit2 } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";

import noimageavailable from "../../assets/no-image.png";

const CMSGalleryCard = ({
  galleryObj,
  onClickEdit,
  setGalleryDeleteId,
  setShowDelete,
  onClickDelete,
}) => {
  return (
    <div>
      <div className="font-bold pb-2">{galleryObj?.album_name}</div>

      <div>
        {galleryObj?.images_list ? (
          <img
            src={galleryObj?.images_list[0]?.gallery_file_url}
            alt="event"
            className="h-48"
          />
        ) : (
          <img src={noimageavailable} alt="gallery" className="h-48" />
        )}
      </div>

      <div className="flex gap-x-3 pt-2">
        <div
          onClick={() => onClickEdit(galleryObj)}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <FiEdit2 />
        </div>
        <div
          onClick={() => {
            setGalleryDeleteId(galleryObj?.gallery_id || null);
            setShowDelete(true);
          }}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <BsTrash3 />
        </div>
      </div>
    </div>
  );
};

export default CMSGalleryCard;
