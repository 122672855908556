import React, { useState, useEffect } from "react";

import axios from "../../../api/axios";
import {
  EVENT_DETAILS_CONFIG_URL,
  GALLERY_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import ViewEventDetails from "./view_event_details.component";
import ViewGalleryDetails from "./view_gallery_details.component";

export default function Gallery() {
  const [galleryCompleteDetailsList, setGalleryCompleteDetailsList] = useState(
    []
  );
  const [eventCompleteDetailsList, setEventCompleteDetailsList] = useState([]);
  const [showAlbumDetails, setShowAlbumDetails] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  const getGalleryCompleteDetailsList = async () => {
    try {
      const response = await axios.get(GALLERY_DETAILS_CONFIG_URL);

      response.status === 200 &&
        setGalleryCompleteDetailsList(response?.data?.galleryCompleteDetails);
      response.status === 202 &&
        console.log("No gallery found in the system.", "error");
    } catch (error) {
      console.error("getEventCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getEventCompleteDetailsList = async () => {
    try {
      const response = await axios.get(EVENT_DETAILS_CONFIG_URL);
      // console.log("Event with complete details List", { response });
      response.status === 200 &&
        setEventCompleteDetailsList(response.data.eventCompleteDetails);
      response.status === 202 &&
        console.log("No events found in the system.", "error");
    } catch (error) {
      console.error("getEventCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowAlbumDetails(false);
      setSelectedAlbum(null);
      getGalleryCompleteDetailsList();
      getEventCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const onAlbumClickHandler = (albumObj) => {
    setSelectedAlbum(albumObj);
    setShowAlbumDetails(true);
  };

  let eventAddedToGalleryList = eventCompleteDetailsList?.filter(
    (eventObj) => eventObj?.is_added_to_gallery === true
  );

  return (
    <section className="m-8 md:m-20">
      <>
        {showAlbumDetails ? (
          <>
            <ViewGalleryDetails
              selectedAlbum={selectedAlbum}
              setSelectedAlbum={setSelectedAlbum}
              setShowAlbumDetails={setShowAlbumDetails}
            />
          </>
        ) : (
          <>
            <div className="mb-16 md:mb-28 flex justify-center text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
              Gallery
            </div>

            <div>
              {galleryCompleteDetailsList?.length > 0 ? (
                <>
                  <div className="mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-20">
                    {galleryCompleteDetailsList?.map((albumObj, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          onAlbumClickHandler(albumObj);
                        }}
                        className="cursor-pointer"
                      >
                        <div>
                          <img
                            src={albumObj?.images_list[0]?.gallery_file_url}
                            className="h-[50vh] w-full object-cover"
                          />
                        </div>

                        <div className="font-semibold text-center mt-4">
                          {albumObj?.album_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex justify-center text-sm text-primary py-2">
                  Gallery content to be uploaded soon.
                </div>
              )}

              {eventAddedToGalleryList &&
              eventAddedToGalleryList?.length > 0 ? (
                <>
                  <div className="mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-20">
                    {eventAddedToGalleryList?.map((albumObj, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          onAlbumClickHandler(albumObj);
                        }}
                        className="cursor-pointer"
                      >
                        <div>
                          <img
                            src={albumObj?.images_list[0]?.file_url}
                            className="h-[50vh] w-full object-cover"
                          />
                        </div>

                        <div className="font-semibold text-center mt-4">
                          {albumObj?.album_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex justify-center text-sm text-primary py-2">
                  {/* No albums configured in the system. */}
                </div>
              )}
            </div>
          </>
        )}
      </>
    </section>
  );
}
