import React, { useState } from "react";

import GenericModal from "./generic_image_modal.component";

const AwardsCard = ({ awardObj }) => {
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState();

  const onImageClickHandler = (imgObj) => {
    setShowModal(true);
    setClickedImage(imgObj);
  };

  const onCloseClickHandler = () => {
    setShowModal(false);
    setClickedImage({});
  };
  return (
    <section>
      {/* <div className="shadow-lg transform transition duration-700 hover:scale-110"> */}
      <div className="shadow-lg h-[70vh] md:h-[90vh] lg:h-[80vh]">
        <div className="flex mb-2">
          <img
            src={awardObj?.award_image_url}
            onClick={() => onImageClickHandler(awardObj)}
            className="h-72 md:h-96 w-full cursor-pointer"
          />
        </div>
        <div className="pl-2 font-bold text-base lg:text-lg text-black">
          {awardObj?.award_name}
        </div>

        <div className="pt-1 pl-2 font-bold text-xs lg:text-sm text-black">
          {awardObj?.award_year}
        </div>

        <div className="pt-1 pl-2 text-sm lg:text-base text-black pb-5">
          {awardObj?.award_description}
        </div>
      </div>

      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        clearData={setClickedImage}
        isAdd={true}
        isLarge={true}
      >
        <div className="relative">
          <img
            src={awardObj?.award_image_url}
            className="object-cover h-[80vh] p-5"
          />
          <span
            onClick={() => onCloseClickHandler()}
            className="absolute top-0 right-0 bg-white text-black font-semibold text-sm h-6 w-6 rounded-full flex flex-col items-center justify-center cursor-pointer"
          >
            x
          </span>
        </div>
      </GenericModal>
    </section>
  );
};

export default AwardsCard;
