import React, { useState } from "react";

import { BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";

import CMSBODCard from "../../../reusable-components/cms-bod-card/cmsBODCard.component";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function BODList({
  bodCompleteDetailsList,
  getBodCompleteDetailsList,
  setEditBodDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ bodCompleteDetailsList });

  const [showDelete, setShowDelete] = useState(false);
  const [bodDeleteId, setBodDeleteId] = useState(null);

  const onClickEdit = (bod) => {
    // console.log("bod received inside Edit: ", bod);
    setEditBodDetails(bod);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (bod) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", bodDeleteId);
    try {
      let response = "";
      if (bodDeleteId) {
        response = await axios.post(
          `${BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL}/delete`,
          {
            bod_id: bodDeleteId,
          }
        );
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log(
          "Selected Director has been deleted successfully.",
          "success"
        );
        getBodCompleteDetailsList();
      } else {
        console.log("Selected Director deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setBodDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        Board Of Directors Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10 mr-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add Board Of Director</div>
          </div>
        </div>
      </div>

      <div>
        {bodCompleteDetailsList?.length > 0 ? (
          <div className="grid grid-cols-3 gap-y-10">
            {bodCompleteDetailsList?.map((bodObj) => (
              <div>
                <CMSBODCard
                  bodObj={bodObj}
                  onClickEdit={onClickEdit}
                  setBodDeleteId={setBodDeleteId}
                  setShowDelete={setShowDelete}
                  onClickDelete={onClickDelete}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-20">
            No board of directors configured in the system.
          </div>
        )}
      </div>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The selected director will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </>
  );
}
