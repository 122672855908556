import { useForm } from "react-hook-form";
import SubmitButton from "../reusable-components/buttons/SubmitButton";
import InputCheckbox from "../reusable-components/inputs/InputCheckBox/InputCheckbox";
import Input from "../reusable-components/inputs/InputTextBox/Input";
import { full_width_button } from "../../src/theme/lightTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import { LOGIN_URL, RAS_KEY_URL } from "../api/api_routing_urls";
import ROLES from "../api/roles_list";
import Captcha from "../utilities/captcah/Captcha";
import rsa from "../utilities/rsaFunction/rsaEncryption";
import Dropdown from "../reusable-components/inputs/Dropdowns/Dropdown";
import useGetUserRoleList from "../hook/getApi/useGetUserRoleList";
import Error from "../reusable-components/outputs/Error";
import {
  setAuth,
  setPersist,
} from "../features/authProvider/authProviderSlice";
import { useSelector } from "react-redux";
import { roleRoutes } from "../utilities/constants/role_routes";

const { rsaEncrypt } = rsa;

const LoginForm = () => {
  const { auth } = useSelector((state) => state.authProvider);
  const navigate = useNavigate();
  const location = useLocation();

  const [rsaPublicKey, setRsaPublicKey] = useState();

  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");
  const [capchaSessionId, setCapchaSessionId] = useState("");
  const [rsaSessionId, setRsaSessionId] = useState("");
  const [userName, setUserName] = useState("");
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const defaultValues = {
    username: "",
    password: "",
    persistuser: false,
    roleid: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RAS_KEY_URL);
      if (response?.data?.status === 201) {
        setRsaPublicKey(response?.data?.publicKey);
        setRsaSessionId(response?.data?.session_uuid);

        console.log(
          "response?.data?.session_uuid",
          response?.data?.session_uuid
        );
      } else {
        setRsaPublicKey("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  console.log({ sessionId: capchaSessionId });
  console.log({ rsaSessionId });

  const onSubmit = async (data) => {
    if (captchaSuccess && rsaPublicKey) {
      data.encryptedPassword = rsaEncrypt(data?.password, rsaPublicKey);

      try {
        const response = await axios.post(
          LOGIN_URL,
          {
            username: data?.username,
            pwd: data?.encryptedPassword,
            captcha: captchaInput,
            session_id: capchaSessionId,
            rsa_session_id: rsaSessionId,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.data?.status !== 202 && response.data?.errMsg === "") {
          let userdetails = {
            username: response.data.userdetails.username,
            role: response.data.userdetails.roleid,
            accessToken: response.data.userdetails.accesstoken,
            photo: response.data.userdetails.photo,
            rolename: response.data.userdetails.rolename,
            fullname: response.data.userdetails.fullname,
            userid: response.data.userdetails.userid,
          };

          setAuth((prev) => ({ ...prev, ...userdetails }));
          // setUser("");
          // setPwd("");
          const roleID = parseInt(response.data.userdetails.roleid);

          // storing roleid in local storage
          localStorage.setItem("roleid", roleID);

          const from = roleRoutes[roleID] || "/unauthorized";

          navigate(from, { replace: true });
        } else {
          setErrMsg(response.data?.errMsg);
          errRef.current.focus();
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 422) {
          setErrMsg("Invalid Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      }
    } else {
      console.error("Captcah is not valid");
    }
    let form = document.getElementById("loginForm");
    setCaptchaReset((prev) => !prev);
    form.reset();
  };

  const onChangeRememberMe = () => {
    setPersist((prev) => !prev);
  };

  const hideError = (e) => {
    setErrMsg("");
  };

  const onUsernameLoad = (e) => {
    setUserName(e?.target?.value);
  };

  const onUserNameChange = (e) => {
    setUserName(e?.target?.value);
  };

  useEffect(() => {
    getPublicKeyRsa();
  }, []);

  useEffect(() => {
    if (auth && Object?.keys(auth)?.length > 0) {
      const roleID = parseInt(auth.role);
      const from = roleRoutes[roleID] || "/unauthorized";
      navigate(from, { replace: true });
    }
  }, [auth]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id="loginForm">
        <p
          ref={errRef}
          className={errMsg ? "px-2 py-1 bg-red-100 text-red-800" : "hidden"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Input
          defaultName="username"
          register={register}
          name="User Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter user name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-4`}
          onChangeInput={hideError}
          defaultValue={defaultValues.username}
          setValue={setValue}
          onBlur={onUserNameChange}
          onLoad={onUsernameLoad}
        />
        <Input
          defaultName="password"
          register={register}
          name="Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter password"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-2`}
          onChangeInput={hideError}
          defaultValue={defaultValues.password}
          setValue={setValue}
        />

        <InputCheckbox
          defaultName="persistuser"
          register={register}
          name="Remember Me"
          required={false}
          pattern={null}
          errors={errors}
          placeholder=""
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="checkbox"
          classes={`rounded shadow-lg text-sm mr-2 `}
          onChangeInput={onChangeRememberMe}
        />
        <Captcha
          setCaptchaSuccess={setCaptchaSuccess}
          captchaReset={captchaReset}
          setCaptchaInput={setCaptchaInput}
          setSessionId={setCapchaSessionId}
        />
        <SubmitButton
          isSubmitting={isSubmitting}
          label="Login"
          className={`${full_width_button} mt-6 `}
          isDisabled={!captchaSuccess}
        />
        <Link to="/">
          <div className="mt-4 w-full flex justify-center items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-slate-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <label className="text-slate-500 cursor-pointer">
              &nbsp;Go to Home
            </label>
          </div>
        </Link>
      </form>
    </>
  );
};

export default LoginForm;
