import React from "react";

import { FiEdit2 } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";

const CMSExecutiveCard = ({
  executiveObj,
  onClickEdit,
  setSmuExecutiveDeleteId,
  setShowDelete,
  onClickDelete,
}) => {
  return (
    <div>
      <div>
        <img
          src={executiveObj?.executive_image_url}
          alt="executive"
          className="h-48"
        />
      </div>

      <div className="font-semibold py-2">
        {executiveObj?.executive_designation},{" "}
        {executiveObj?.executive_designation_description}
      </div>

      <div className="flex gap-x-3 pt-2">
        <div
          onClick={() => onClickEdit(executiveObj)}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <FiEdit2 />
        </div>
        <div
          onClick={() => {
            setSmuExecutiveDeleteId(executiveObj?.executive_id || null);
            setShowDelete(true);
          }}
          className="p-2 border rounded-md border-primary cursor-pointer"
        >
          <BsTrash3 />
        </div>
      </div>
    </div>
  );
};

export default CMSExecutiveCard;
