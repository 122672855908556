import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../reusable-components/inputs/InputTextBox/Input";
import Dropdown from "../reusable-components/inputs/Dropdowns/Dropdown";
import useGetUserRoleList from "../hook/getApi/useGetUserRoleList";
import Error from "../reusable-components/outputs/Error";
import Captcha from "../utilities/captcah/Captcha";
import SubmitButton from "../reusable-components/buttons/SubmitButton";
import { full_width_button } from "../theme/lightTheme";
import axios from "../api/axios";
import { LOGIN_USING_COOKIE_URL } from "../api/api_routing_urls";
import PasswordInput from "../reusable-components/inputs/InputTextBox/PasswordInput";

export default function LoginFormUsingCookie() {
  const [errMsg, setErrMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [selectedRole, setSelectedRole] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loginUsingCookieList, setLoginUsingCookieList] = useState([]);

  const errRef = useRef();

  const defaultValues = {
    username: "",
    user_password: "",
    role_id: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.get(LOGIN_USING_COOKIE_URL, {
        params: {
          username: data.username,
          user_password: data.user_password,
          role_id: data.role_id?.value,
        },
      });

      response.status === 200 &&
        setLoginUsingCookieList(
          response.data.LoginDetailsUsingCookieList[0]
            ?.handle_user_login_using_cookie
        );

      if (
        response.data.LoginDetailsUsingCookieList[0]
          ?.handle_user_login_using_cookie?.role_Id !== null &&
        response.data.LoginDetailsUsingCookieList[0]
          ?.handle_user_login_using_cookie?.role_Id !== undefined
      ) {
        window.location.href = "/sysadmin/dashboard";
        localStorage.setItem("Content Admin", "logged-In");
        return;
      }

      response.status === 202 &&
        console.log("No  found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const hideError = (e) => {
    setErrMsg("");
  };

  const onUserNameChange = (e) => {
    setUserName(e?.target?.value);
  };

  const onUsernameLoad = (e) => {
    setUserName(e?.target?.value);
  };

  // const { data, error, loading } = useGetUserRoleList(userName);

  let roleListDetailsDD = [
    { label: "Super Admin", value: 1 },
    {
      label: "Content Admin",
      value: 2,
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p
          ref={errRef}
          className={errMsg ? "px-2 py-1 bg-red-100 text-red-800" : "hidden"}
          aria-live="assertive"
        >
          {errMsg}
        </p>

        <Input
          defaultName="username"
          register={register}
          name="User Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter user name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-4`}
          onChangeInput={hideError}
          defaultValue={defaultValues.username}
          setValue={setValue}
          onBlur={onUserNameChange}
          onLoad={onUsernameLoad}
        />

        {/* <Input
          defaultName="user_password"
          register={register}
          name="Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter password"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-2`}
          onChangeInput={hideError}
          defaultValue={defaultValues.user_password}
          setValue={setValue}
        /> */}

        <PasswordInput
          id="myPasswordInput"
          defaultName="user_password"
          register={register}
          name="Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter password"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full mb-2`}
          onChangeInput={hideError}
          defaultValue={defaultValues.user_password}
          setValue={setValue}
        />

        <Dropdown
          defaultName="role_id"
          register={register}
          labelname="Role Name"
          required={true}
          pattern={null}
          errors={errors}
          classes={`text-sm w-full z-60`}
          setError={setError}
          clearError={clearErrors}
          onChangeInput={null}
          control={control}
          data={roleListDetailsDD}
          defaultValue={selectedRole}
          setValue={setValue}
          setSelected={setSelectedRole}
          selected={selectedRole}
          maxMenuHeight={120}
        />
        {/* {error && <Error message={error}></Error>} */}

        <SubmitButton
          isSubmitting={isSubmitting}
          label="Login"
          className={`${full_width_button} mt-6 `}
          // isDisabled={!captchaSuccess}
        />
      </form>
    </>
  );
}
