import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import plantbannerimage from "../../../assets/plant.jpg";
import facilitiesplanttable from "../../../assets/facilitiesplanttable.png";

const FacilitiesPlant = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img src={plantbannerimage} className="object-fill w-full  h-full" />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Gangtok Dairy Plant
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            Gangtok Dairy Plant is an ISO 22000:2005 certified Unit. The plant
            can handle around 45000-50000 Lts.Of Milk per day.The plant has two
            important wings viz, Production Unit and Engineering Unit.
            Production Unit Includes: Milk reception dock, Crate and Can
            Washing, Milk processing, Milk packaging, Products manufacturing and
            packaging, cold stores, Laboratory, Milk storage, General stores,
            Air compressor, Hot water generation and Cleaning in Place. Milk
            Processing unit is the heart of the Dairy Plant and in this unit
            pasteurization of milk is carried out to destroy bacteria in milk
            thereby rendering the milk safe and fit for consumption and also the
            process enhances the shelf life of milk. Engineering Unit includes:
            Boiler, Refrigeration, DG sets, Effluent Treatment Plant and
            Electrical activities. The Plant has staff strength of 50. The
            categories are Casual MR, regular MR, Consolidated and regular pay
            scale. Most of the employees have undergone training on operation
            and maintainence of machines the handle and activities they are
            responsible for.
          </div>

          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            Gangtok Plant attracts Students from almost all Universities and
            colleges of Sikkim. Visit of school students and students from
            institutions, internship programmes and project works are regular
            affairs of Gangtok Dairy Plant. Also, the plant hosts visits of
            important dignitaries from all walks of life including farmers and
            officials from neighbouring country of Bhutan. The Plant id guided
            by Food Safety Policy and under ISO regulation the Plant obtains
            required clearances from important Departments and conducts yearly
            food safety audit for continual improvement in Quality Management.
            Gangtok Plant has undergone major turnaround over the past 10
            years.Infrastructure developement, introduction of new machineries,
            replacement of old machineries, repackaging of products, training
            and developement, meodernisation and few of interventions.
          </div>

          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            The Plant also successfully accomplished Asian Productivity
            Organisation Project in the smaller states co-operative dairy
            category in the year 2013. Technical intervention in culture used
            for Curd production and Total Quality Management were the
            highlights. Five Milk Tankers of total 40 thousand capacity operates
            under Gangtok Dairy Plant for evacuation of milk from the other
            Plant of Sikkim Milk Union. The Following products are manufactured
            at the Dairy Plants at Tadong, Gangtok and Karfectar, Jorethang.
          </div>

          <div className="flex justify-center mt-6 md:mt-4">
            <img src={facilitiesplanttable} className="object-fill h-full" />
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">Facilities</div>
          <div className="flex flex-col py-2 border border-gray-300">
            <Link to="/facilities-head-office">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Head Office
              </div>
            </Link>
            <Link to="/facilities-regional-office">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Regional Office
              </div>
            </Link>
            <Link to="/facilities-plant">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Plant
              </div>
            </Link>
            <Link to="/facilities-dairy-and-co-operative-societies">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Dairy and Co-operative Societies
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilitiesPlant;
