import React, { Fragment, useEffect, useState } from "react";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import AddButton from "../../../reusable-components/buttons/AddButton";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { SlOptionsVertical } from "react-icons/sl";
import { Menu, Transition } from "@headlessui/react";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { HiOutlineSaveAs } from "react-icons/hi";
import { MdOutlinePublish } from "react-icons/md";
import axios from "../../../api/axios";
import { NOTICE_DETAILS_URL } from "../../../api/api_routing_urls";
import ConfirmationModal from "../../../reusable-components/modals/ConfirmationModal";

export default function NoticeList({
  setShowAddForm,
  noticeList,
  setEditNoticeDetails,
  setNoticeId,
  setShowDelete,
  getNoticeDetails,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleEdit = (noticeObj) => {
    setEditNoticeDetails(noticeObj);
    setShowAddForm(true);
  };

  const handleDelete = (notice_id) => {
    setShowDelete(true);
    setNoticeId(notice_id);
  };

  const handleDraft = async (notice_id) => {
    let sendDataObj = {
      notice_id: notice_id,
      is_publish: false,
      updated_by: "admin",
    };

    try {
      const response = await axios.post(
        `${NOTICE_DETAILS_URL}/update-status`,
        sendDataObj
      );
      getNoticeDetails();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePublish = async (notice_id) => {
    let sendDataObj = {
      notice_id: notice_id,
      is_publish: true,
      updated_by: "admin",
    };

    try {
      const response = await axios.post(
        `${NOTICE_DETAILS_URL}/update-status`,
        sendDataObj
      );

      getNoticeDetails();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {noticeList === null || noticeList == undefined ? (
        <p className="boreder border-2 p-4 mt-8">
          Notice has not been added till now !!!{" "}
        </p>
      ) : (
        <>
          <section className="py-8">
            <TableWrapper>
              <thead className="bg-gray-200 text-sm">
                <th
                  scope="col"
                  className="px-6 py-3 font-medium text-gray-900  tracking-wider text-left"
                >
                  Sl. No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                >
                  Notice Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                >
                  End Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                >
                  Notice Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right font-medium text-gray-900  tracking-wider"
                >
                  Action
                </th>
              </thead>
              <tbody>
                {noticeList?.length > 0 &&
                  noticeList.map((noticeObj, index) => (
                    <tr
                      key={index}
                      className="relative p-3 rounded-md bg-white mb-2 border-b shadow cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {parseInt(index + 1)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {noticeObj?.notice_title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {noticeObj?.notice_published_startdate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {noticeObj?.notice_published_enddate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {noticeObj?.notice_type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {noticeObj?.is_publish ? (
                          <span className="border px-2.5 py-1 rounded-full text-xs font-medium bg-[#014E16] text-white ">
                            Published
                          </span>
                        ) : (
                          <span className="border px-2.5 py-1 rounded-full text-xs font-medium bg-[#924F02] text-white">
                            Draft
                          </span>
                        )}
                      </td>

                      <td className="py-4  whitespace-nowrap text-sm flex justify-center ">
                        {
                          <div className="flex justify-end">
                            <Menu as="div" className="block text-left">
                              <div>
                                <Menu.Button className="">
                                  <div className="flex items-center gap-3">
                                    <SlOptionsVertical size={16} />
                                    {/* <p>Manage</p> */}
                                  </div>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute z-10 origin-top-right  right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          onClick={() => handleEdit(noticeObj)}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                        >
                                          <div className="flex items-center gap-3 text-black hover:text-primary">
                                            <FiEdit3 size={16} />
                                            <p className="">View/Edit</p>
                                          </div>
                                        </div>
                                      )}
                                    </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          onClick={() =>
                                            handleDelete(noticeObj?.notice_id)
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                        >
                                          <div className="flex items-center gap-3 text-black hover:text-primary">
                                            <AiOutlineDelete size={16} />
                                            <p className="">Delete</p>
                                          </div>
                                        </div>
                                      )}
                                    </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                        <div
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "block px-4 py-2 text-sm cursor-pointer"
                                          )}
                                        >
                                          {noticeObj?.is_publish ? (
                                            <>
                                              <div
                                                className="flex items-center gap-3 text-black hover:text-primary"
                                                onClick={() =>
                                                  handleDraft(
                                                    noticeObj?.notice_id
                                                  )
                                                }
                                              >
                                                <HiOutlineSaveAs size={16} />
                                                <p className="">Draft</p>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                className="flex items-center gap-3 text-black hover:text-primary"
                                                onClick={() =>
                                                  handlePublish(
                                                    noticeObj?.notice_id
                                                  )
                                                }
                                              >
                                                <MdOutlinePublish size={16} />
                                                <p className="">Publish</p>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableWrapper>
          </section>
        </>
      )}

      {/* <ConfirmationModal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        message={`Are you sure you wnat to publish this notice in the website?`}
        btnName={"Confirm"}
        onConfirm={() => handlePublish()}
      ></ConfirmationModal> */}
    </>
  );
}
