import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../api/axios";
import { MILESTONE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AddMilestoneForm = ({
  getMilestoneCompleteDetailsList,
  editMilestoneDetails,
  setEditMilestoneDetails,
  setShowAddForm,
  setIsAdd,
}) => {
  const isEdit = Object.keys(editMilestoneDetails)?.length > 0;

  const defaultValues = {
    milestone_id: !isEdit ? "" : editMilestoneDetails?.milestone_id,
    milestone_title: !isEdit ? "" : editMilestoneDetails?.milestone_title,
    milestone_year: !isEdit ? "" : editMilestoneDetails?.milestone_year,
    milestone_description: !isEdit
      ? ""
      : editMilestoneDetails?.milestone_description,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      let sendDataObj = {
        milestone_title: data.milestone_title,
        milestone_year: data.milestone_year,
        milestone_description: data.milestone_description,
      };

      let response = "";
      if (!isEdit) {
        sendDataObj.createdby = "admin";
        response = await axios.post(MILESTONE_DETAILS_CONFIG_URL, sendDataObj);
      } else {
        sendDataObj.updatedby = "admin";
        sendDataObj.milestone_id = editMilestoneDetails?.milestone_id;

        response = await axios.post(
          `${MILESTONE_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditMilestoneDetails({});
          console.log(
            "Milestone details have been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "Milestone details have been added successfully.",
            "success"
          );
        }
        getMilestoneCompleteDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Milestone Details Error:", response.data);
        } else {
          if (response.data.saveMilestoneDetails.includes("unique_milestone")) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="milestone_title"
          register={register}
          name="Milestone Title"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter milestone title"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.milestone_title}
          setValue={setValue}
        />

        <Input
          defaultName="milestone_year"
          register={register}
          name="Milestone Year"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter the year the milestone was achieved"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="number"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.milestone_year}
          setValue={setValue}
        />

        <TextArea
          defaultName="milestone_description"
          register={register}
          name="Milestone description"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Description of the milestone"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full resize-none h-32 focus:border-primary focus:outline-none focus:ring-0`}
          onChangeInput={null}
          defaultValue={defaultValues.milestone_description}
          setValue={setValue}
        />

        <div className="flex justify-between mt-10">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditMilestoneDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel Milestone Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddMilestoneForm;
