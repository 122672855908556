import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import aboutusprofilebannerimage from "../../../assets/profile.jpg";

const AboutUsProfile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusprofilebannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Company Profile
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            On the merger of Sikkim with the Indian Dominion in 1975 the then
            Governor of Sikkim – Shri B.B.Lal initiated the idea of forming a
            Co-operative Milk Union in Sikkim, probably being encouraged with
            the success that had been achieved in the State of Gujarat.
            Therefore, the matter was taken up with Late Dr..V.Kurien, the then
            Chairman of National Dairy Development Board(NDDB) who also paid a
            visit to Sikkim in 1977 as a guest of the State. After discussion
            with the State Government Dr.Kurien agreed to implement “Anand
            Pattern” Co-operative societies in Sikkim.
          </div>

          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            Accordingly, from 1978 the Spear Head Team of NDDB started working
            in the three districts of the State viz East, West and South. Prior
            to this, Sikkim Livestock Development Corporation (SLDC) looked
            after the dairying business since its existence in 1977. Ultimately,
            on 1.7.1980, Sikkim Co-operative Milk Producers’ Union Limited was
            formed after being registered under the State Co-operative Societies
            Act, 1978 and having taken over the project from NDDB, it started
            functioning at 5th Mile, Tadong Gangtok and at Karfectar, Jorethang
            with a Processing Capacity of 10,000 Ltr per day at Gangtok Dairy
            Plant and 5,000 per day at Jorethang Dairy Plant with effect from
            November 1981, as an apex level (two-tier) Organisation of the
            primary Milk Producers’ Co-operative Societies (MPCS) at village
            level. The Sikkim Milk Union came into being during the Operation
            Flood –II programme of the NDDB and was established through
            financial assistance by the erstwhile Indian Dairy Corporation (IDC)
            and technical guidance of the implementing agency, the NDDB.
          </div>

          <div className="text-green-500 text-sm md:text-base flex justify-center mt-2">
            Organizational Philosophy
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            The primary aim of the Sikkim Milk Union is to provide remunerative
            market for milk producers in the far-flung remote villages and make
            hygienic milk and milk products available to the urban consumers at
            reasonable rates thereby achieving the following objectives:
          </div>

          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Stabilize price of milk by connecting areas of surplus milk to
                  the milk-deficient towns and cities.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Uplift the socio-economic condition of the rural poor by
                  increasing their income through sales of their milk.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enhance milk production of milch animals by providing
                  technical inputs such as balanced milch ration, fodder
                  saplings thus helping to increase the income of the farmers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enhance milk yield of the milch cattle of the farmers by
                  improving their genetic potential through artificial
                  insemination and by providing pedigree bulls for natural
                  service.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Provide year-round assured market for the milk of the farmers
                  unlike other agricultural produce.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Provide rural employment both as dairy farmers and as
                  employees of the Milk Producers Cooperative Society as
                  Secretary/Tester/Helper.
                </span>
              </li>
            </ul>
          </div>
          <div className="text-[#1466A2] text-sm md:text-base flex justify-center mt-2 capitalize font-medium ">
            Number of employees of Sikkim Milk Union as on 30th November 2023.
          </div>

          <div className="mt-6 overflow-x-auto ">
            <table className=" border">
              <thead className="bg-white text-sm">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Unit
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Regular
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Consolidated
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Muster Roll
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Casual
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium text-gray-900  tracking-wider"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
                <tr className="border-t">
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    Gangtok
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">49</td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">27</td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">24</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">16</td>
                </tr>

                <tr className="border-t">
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    Jorethang
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">25</td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">25</td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm">16</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">6</td>
                </tr>

                <tr className="border-t">
                  <td className="px-6 py-4 whitespace-nowrap text-sm">TOTAL</td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold">
                    74
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold">
                    52
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold">
                    40
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold">
                    22
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold">
                    188
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsProfile;
