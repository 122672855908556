import React, { useState, useEffect, useRef } from "react";

import NavHeaderLogo from "../../../assets/smunavheaderlogo.jpg";
import BannerImage from "../../../assets/banner_img_2.jpg";
import AboutusImage from "../../../assets/banner_img_1.jpg";
import Footer from "../common/Footer/Footer";
import { Link } from "react-router-dom";

const WelcomePage = () => {
  return (
    <>
      <div className="flex  w-full items-center  border b-2">
        <div className="font-body">
          <div className="sticky-sm-top">
            <div className="hidden md:h-24 md:flex  md:px-8 md:py-2 ">
              <img src={NavHeaderLogo} />
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="relative">
          <div className="bg-black opacity-80 h-72 lg:h-[500px] w-full object-cover absolute"></div>
          <img
            src={BannerImage}
            alt="Banner Image"
            className="h-72 lg:h-[500px] w-full object-cover"
          />
          <div className="absolute top-[40%] md:top-1/2 -translate-y-2/4 md:left-10 lg:left-24 xl:left-72">
            <h1 className=" 2xl:text-5xl lg:text-3xl text-base  text-white text-center  ">
              The Sikkim Co-operative Milk Producers' Union Ltd
            </h1>
            <p className=" text-white font-light text-center text-xs lg:text-sm pt-4 ">
              "Explore the richness of Sikkim Milk's offerings on our app and
              website – your gateway to quality dairy products and delightful
              experiences."
            </p>
          </div>

          <div className="absolute top-[60%] translate-y-1/2  left-[55%] lg:left-[50%]  pt-4">
            <a
              href="#"
              className=" text-white text-xs lg:text-sm bg-primary  px-2.5 md:px-5 py-2.5 cursor-pointer hover:underline rounded-sm "
            >
              Mitra App
            </a>
          </div>
          <div className="absolute top-[60%] translate-y-1/2 left-[25%] md:left-[35%] xl:left-[40%]  pt-4 ">
            <a
              href="https://marketing.sikkimmilk.com/"
              target="_blank"
              className="text-white text-xs lg:text-sm bg-transparent border  px-2 md:px-4 py-2 cursor-pointer hover:underline rounded-sm"
            >
              Explore More
            </a>
          </div>
        </div>
      </section>

      <div className=" mt-10">
        <h1 className=" 2xl:text-5xl lg:text-2xl text-black pl-10 ">
          About Us
        </h1>
        <div className="w-7 border border-primary mt-1 ml-10"></div>
      </div>

      <section className="lg:hidden px-8">
        <div className=" mt-4 md:mt-6 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col ">
            <div className="relative flex justify-center lg:justify-end ">
              <img src={AboutusImage} className="rounded-md w-auto" />
            </div>
          </div>

          <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
            <p className="leading-relaxed text-gray-700 mt-6">
              On the merger of Sikkim with the Indian Dominion in 1975 the then
              Governor of Sikkim – Shri B.B.Lal initiated the idea of forming a
              Co-operative Milk Union in Sikkim, probably being encouraged with
              the success that had been achieved in the State of Gujarat.
              Therefore, the matter was taken up with Late Dr..V.Kurien, the
              then Chairman of National Dairy Development Board(NDDB) who also
              paid a visit to Sikkim in 1977 as a guest of the State. After
              discussion with the State Government Dr.Kurien agreed to implement
              “Anand Pattern” Co-operative societies in Sikkim. Accordingly,
              from 1978 the Spear Head Team of NDDB started working in the three
              districts of the State viz East, West and South. Prior to this,
              Sikkim Livestock Development Corporation (SLDC) looked after the
              dairying business since its existence in 1977. Ultimately, on
              1.7.1980, Sikkim Co-operative Milk Producers’ Union Limited was
              formed after being registered under the State Co-operative
              Societies Act, 1978 and having taken over the project from NDDB,
              it started functioning at 5th Mile, Tadong Gangtok and at
              Karfectar, Jorethang with a Processing Capacity of 10,000 Ltr per
              day at Gangtok Dairy Plant and 5,000 per day at Jorethang Dairy
              Plant with effect from November 1981, as an apex level (two-tier)
              Organisation of the primary Milk Producers’ Co-operative Societies
              (MPCS) at village level.
            </p>
          </div>
        </div>
      </section>

      <section className="hidden lg:block px-8">
        <div className=" mt-4 grid grid-cols-1 lg:grid-cols-2">
          <div className="text-sm lg:text-base 2xl:text-[20px] font-normal  lg:mb-0 lg:pr-8 text-justify">
            <p className="leading-relaxed text-sm text-gray-700">
              On the merger of Sikkim with the Indian Dominion in 1975 the then
              Governor of Sikkim – Shri B.B.Lal initiated the idea of forming a
              Co-operative Milk Union in Sikkim, probably being encouraged with
              the success that had been achieved in the State of Gujarat.
              Therefore, the matter was taken up with Late Dr..V.Kurien, the
              then Chairman of National Dairy Development Board(NDDB) who also
              paid a visit to Sikkim in 1977 as a guest of the State. After
              discussion with the State Government Dr.Kurien agreed to implement
              “Anand Pattern” Co-operative societies in Sikkim. Accordingly,
              from 1978 the Spear Head Team of NDDB started working in the three
              districts of the State viz East, West and South. Prior to this,
              Sikkim Livestock Development Corporation (SLDC) looked after the
              dairying business since its existence in 1977. Ultimately, on
              1.7.1980, Sikkim Co-operative Milk Producers’ Union Limited was
              formed after being registered under the State Co-operative
              Societies Act, 1978 and having taken over the project from NDDB,
              it started functioning at 5th Mile, Tadong Gangtok and at
              Karfectar, Jorethang with a Processing Capacity of 10,000 Ltr per
              day at Gangtok Dairy Plant and 5,000 per day at Jorethang Dairy
              Plant with effect from November 1981, as an apex level (two-tier)
              Organisation of the primary Milk Producers’ Co-operative Societies
              (MPCS) at village level.
            </p>
          </div>

          <div className="flex flex-col">
            <div className="relative flex justify-end ">
              <img src={AboutusImage} className="rounded-md w-auto" />
            </div>
          </div>
        </div>
      </section>

      <div className=" lg:mt-10">
        <h1 className=" 2xl:text-5xl lg:text-2xl text-black pl-10   ">
          Mitra App
        </h1>
        <div className="w-7 border border-primary mt-1 ml-10 "></div>
      </div>

      <section className="px-10">
        <div className=" mt-4 mb-5 lg:mb-0 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-16 ">
          <div className="flex flex-col">
            <div className="relative flex lg:justify-end shadow-2xl">
              <img src={BannerImage} className="rounded-md w-auto" />
            </div>
          </div>
          <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify ">
            <p className="leading-relaxed text-gray-700 mt-6 lg:mt-0">
              An Internet-Based Product Distribution Management solution (Web
              Portal + Mobile App) encompassing all aspects of the organization
              related to Plant operation, Distribution & Marketing. The solution
              is a combination of a web application typically accessed using a
              desktop browser and a mobile app. The app will have features such
              as: Online Ordering : All Sikkim Milk Union registered agents can
              order products on a daily basis as per their requirement. Order
              history : Agents can view details regarding all the orders they
              have placed Online Payment : Agents after placing the order for
              Sikkim Milk Products will have a provision to make payment online
              through a dedicated payment gateway. Wallet Option : All
              registered users now have access to a wallet option, wherein
              refunds or reimbursements from the Sikkim Milk Union will be
              credited, and the wallet's balance will automatically be utilized
              when they place their subsequent orders.
            </p>
          </div>
        </div>
      </section>

      <footer className="">
        <Footer />
      </footer>
    </>
  );
};

export default WelcomePage;
