import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { NOTICE_DETAILS_URL } from "../../../api/api_routing_urls";

export default function ViewTextNotice() {
  const [noticeList, setNoticeList] = useState([]);

  const getNoticeDetails = async () => {
    try {
      const response = await axios.get(NOTICE_DETAILS_URL);
      response.status === 200 &&
        setNoticeList(response?.data?.noticeList[0]?.get_notice_details);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  let publishedNoticedList = noticeList?.filter(
    (noticeObj) =>
      noticeObj?.is_publish === true && noticeObj?.doc_details === null
  );

  useEffect(() => {
    getNoticeDetails();
  }, []);

  return (
    <>
      <div className="my-8 text-center text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
        Notice
      </div>

      <div
        className=" container text-xs md:text-sm lg:text-base lg:mx-auto px-8 text-justify"
        dangerouslySetInnerHTML={{
          __html: publishedNoticedList[0]?.notice_text,
        }}
      />
    </>
  );
}
