import { RadioGroup } from "@headlessui/react";
import { useEffect } from "react";

const noticeType = [
  {
    name: "PDF",
  },
  {
    name: "Image",
  },
  {
    name: "Both Image and PDF",
  },
  {
    name: "Text",
  },
];

export default function RadioButtonforNotice({
  selectedNoticeType,
  setSelectedNoticeType,
  editNoticeDetails,
}) {
  useEffect(() => {
    if (editNoticeDetails?.notice_type) {
      setSelectedNoticeType(
        noticeType.filter(
          (obj) => obj.name === `${editNoticeDetails?.notice_type}`
        )[0]
      );
    } else {
      setSelectedNoticeType(noticeType[0]);
    }
  }, [editNoticeDetails?.notice_type]);
  return (
    <RadioGroup value={selectedNoticeType} onChange={setSelectedNoticeType}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="grid grid-cols-4 gap-4">
        {noticeType.map((noticetypeObj) => (
          <RadioGroup.Option
            key={noticetypeObj.name}
            value={noticetypeObj}
            className={({ active, checked }) =>
              `${
                active
                  ? "ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300"
                  : ""
              }
                  ${checked ? "bg-[#1466A2] text-white " : "bg-white"}
                    relative flex cursor-pointer rounded-sm px-5 py-4 shadow-md focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex  w-full items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium   ${
                          checked ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {noticetypeObj.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={`inline ${
                          checked ? "text-sky-100" : "text-gray-500"
                        }`}
                      >
                        {/* <span>
                              {plan.ram}/{plan.cpus}
                            </span>{" "}
                            <span aria-hidden="true">&middot;</span>{" "}
                            <span>{plan.disk}</span> */}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && (
                    <div className="shrink-0 text-white">
                      <CheckIcon className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
