/* eslint-disable import/no-anonymous-default-export */

const NodeRSA = require("node-rsa");

export default {
  rsaEncrypt(data, publicKey) {
    try {
      // const encryptedMessage = crypto.publicEncrypt(
      //   {
      //     key: publicKey,
      //     padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
      //     oaepHash: "sha256",
      //   },
      //   // We convert the data string to a buffer using `Buffer.from`
      //   Buffer.from(data)
      // );

      const public_key = new NodeRSA(publicKey);

      const encrypted = public_key.encrypt(data, "base64");

      return encrypted;

      // The encrypted data is in the form of bytes, so we print it in base64 format
      // so that it's displayed in a more readable form
      // return encryptedMessage.toString("base64");
    } catch (error) {
      console.error("Encryption Error:", error);
    }
  },
};
