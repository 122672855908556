import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "../../../../reusable-components/buttons/Button";

import NavHeaderLogo from "../../../../assets/smunavheaderlogo.jpg";
import sunawloSikkim from "../../../../assets/sunawlo-sikkim-logo-new-nobg.png";

export default function NavbarHeader() {
  return (
    <>
      <div className="flex justify-between w-full items-center pr-10">
        <div className="font-body">
          <div className="sticky-sm-top flex justify-normal gap-x-3 items-center">
            <div className="hidden md:h-28 md:flex md:px-28 md:py-2">
              <img src={NavHeaderLogo} />
            </div>
            <div className="hidden md:h-28 md:flex md:px-28 md:py-2">
              <img src={sunawloSikkim} />
            </div>
          </div>
        </div>

        <div className="hidden md:block ">
          <Button
            className="border border-[#1466A2] py-2 px-8 bg-white text-[#1466A2] rounded-sm shadow-lg text-sm hover:bg-[#1466A2] hover:text-white"
            onClick={() => window.location.replace("/login")}
            // onClick={() => window.location.replace("/sysadmin/dashboard")}
            aria_label="Login Button"
          >
            Login
          </Button>
        </div>
      </div>
    </>
  );
}
