import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import aboutusobjectivesbannerimage from "../../../assets/homesectionimage.jpg";

const AboutUsObjectives = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusobjectivesbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Our Aim and Objectives
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  To provide remunerative market for milk producers of far -
                  flung remote villages and make hygenic milk available to the
                  urban consumers at a reasonable price thereby achieving
                  following objectives.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Stabilize price of milk by connecting areas of surplus to the
                  milk deficient towns and cities.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Uplift the socio-economic condition of the rural poor by
                  increasing their income through sale of milk.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enhance milk production of milch animals by providing
                  technical inputs such as balanced milch ration, fodder seeds,
                  fodder saplings thus helping to increase income of the
                  farmers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enhance milk yield of milch cattle of farmers by improving
                  genetic potential through artificial insemination and also by
                  providing pedigree bulls for natural service.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Provide year round assured market for milk of farmers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Provide rural employment both as dairy farmers and as paid
                  employees of society - secretaries, testers and helpers.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Capacity building of farmers by providing constant training,
                  induction and motivation.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enable farmers to become self-reliant and foster the spirit of
                  co-operation among them.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Enable farmers to observe discipline and respect to all
                  sections of the society irrespective of, cast, creed, class
                  community, sex, etc.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Above all to create a just (social and economic) Egalitarian
                  society.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsObjectives;
