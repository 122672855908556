import React, { useState, useCallback } from "react";

import { Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { DropzoneDiv } from "./imageDropzone.styles";

import { ImFilesEmpty } from "react-icons/im";
import { AiOutlineUpload } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";

const ImageDropzone = ({ fieldTitle, onChange, multiple, available_image }) => {
  const [loading, setLoading] = useState(false);
  const [imgPreview, setImgPreview] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("File received inside dropzone component: ", acceptedFiles);

    if (acceptedFiles) {
      let fileArr = [];
      onChange(acceptedFiles);
      acceptedFiles.map((mapObj) => {
        fileArr.push(URL.createObjectURL(mapObj));
      });

      setImgPreview(fileArr);
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: "image/*",
  });

  return (
    <>
      <div className="font-semibold text-sm pt-6 pb-2">{fieldTitle}</div>
      <DropzoneDiv {...getRootProps()}>
        <input {...getInputProps()} />

        {imgPreview.length > 0 ? (
          <div className="grid grid-cols-5 gap-5">
            {imgPreview.map((mapObj) => (
              <img src={mapObj} className="px-2" />
            ))}
          </div>
        ) : loading ? (
          <Spinner variant="standard" animation="border" role="status" />
        ) : (
          <>
            {available_image !== "" ? (
              <div>
                <img src={available_image} className="px-2" />
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="pb-16 flex justify-center">
                  <ImFilesEmpty color="#1466A2" size={50} />
                </div>

                <div className="py-3 flex gap-x-4 justify-center items-center border border-primary">
                  <div className=" text-black rounded-sm cursor-pointer">
                    Select Image
                  </div>

                  <div>
                    <AiOutlineUpload color="black" size={20} />
                  </div>
                </div>
                <p className="cursor-pointer pt-5 font-semibold text-black">
                  or, drag and drop image here
                </p>
              </div>
            )}
          </>
        )}
      </DropzoneDiv>
    </>
  );
};

export default ImageDropzone;
