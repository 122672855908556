import React from "react";
import { Routes, Route } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";

import PublicLayout from "./areas/public/PublicLayout";
import Home from "./areas/public/pages/home.component";
import WelcomePage from "./areas/public/pages/welcome_page.component";
import ContactUs from "./areas/public/pages/contact_us.component";
import FacilitiesHeadOffice from "./areas/public/pages/facilities_head_office.component";
import FacilitiesRegionalOffice from "./areas/public/pages/facilities_regional_office.component";
import FacilitiesPlant from "./areas/public/pages/facilities_plant.component";
import FacilitiesDairy from "./areas/public/pages/facilities_dairy.component";
import AboutUsProfile from "./areas/public/pages/about_us_profile.component";
import AboutUsMission from "./areas/public/pages/about_us_mission.component";
import AboutUsObjectives from "./areas/public/pages/about_us_objectives.component";
import AboutUsFunctions from "./areas/public/pages/about_us_functions.component";
import AboutUsAwards from "./areas/public/pages/about_us_awards.component";
import AboutUsBOD from "./areas/public/pages/about_us_bod.component";
import AboutUsExecutives from "./areas/public/pages/about_us_smu_executives.component";
import AboutUsOrgChart from "./areas/public/pages/about_us_org_chart.component";
import AboutUsMilestones from "./areas/public/pages/about-us-milestones.component";
import Events from "./areas/public/pages/events.component";
import Gallery from "./areas/public/pages/gallery.component";
import YoutubeGallery from "./areas/public/pages/youtube_gallery.component";
import Notice from "./areas/public/pages/notice.component";
import ViewTextNotice from "./areas/public/pages/view_text_notice.component";

import RequireAuth from "./authentication/components/RequireAuth";
import ROLES from "./api/roles_list";
import SystemAdminDashboard from "./areas/system-admin/SystemAdminDashboard";
import Login from "./login/Login";
import PersistLogin from "./authentication/components/PersistLogin";
import DevLogin from "./authentication/components/devLogin.component";
import NoticeConfig from "./areas/modules/notice/NoticeConfig";

// CMS Controller Imports

import AwardsConfig from "./areas/modules/awards/awardConfig.component";
import ProductConfig from "./areas/modules/products/productConfig.component";
import BODConfig from "./areas/modules/board_of_directors/bodConfig.component";
import SMUExecutivesConfig from "./areas/modules/smu_executives/smuExecutivesConfig.component";
import MilestoneConfig from "./areas/modules/milestones/milestoneConfig.component";
import EventConfig from "./areas/modules/events/eventConfig.component";
import GalleryConfig from "./areas/modules/gallery/galleryConfig.component";
import YoutubeGalleryConfig from "./areas/modules/youtube_gallery/youtubeGalleryConfig.component";

// import NoticeConfig from "./areas/modules/notice/NoticeConfig";
import PersistLogins from "./authentication/components/persistLogins.component";

function App() {
  const userRolePrefix = "sysadmin";
  const sidebarType = "Super Admin";

  const allowedRoles =
    sidebarType === "Super Admin"
      ? [ROLES["Super Admin"]]
      : sidebarType === "Content Admin"
      ? [ROLES["Super Admin"], ROLES["Content Admin"]]
      : [];

  return (
    <>
      <Routes>
        <Route
          exact
          path="/
        welcome-home"
          element={<WelcomePage />}
        />
        <Route element={<PublicLayout />}>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/events" element={<Events />} />

          <Route exact path="/contact-us" element={<ContactUs />} />

          <Route exact path="/notice" element={<Notice />} />
          <Route exact path="/view-text-notice" element={<ViewTextNotice />} />

          <Route exact path="/gallery" element={<Gallery />} />

          <Route exact path="/our-videos" element={<YoutubeGallery />} />

          <Route
            exact
            path="/facilities-head-office"
            element={<FacilitiesHeadOffice />}
          />

          <Route
            exact
            path="/facilities-regional-office"
            element={<FacilitiesRegionalOffice />}
          />

          <Route exact path="/facilities-plant" element={<FacilitiesPlant />} />

          <Route
            exact
            path="/facilities-dairy-and-co-operative-societies"
            element={<FacilitiesDairy />}
          />

          <Route exact path="/about-us-profile" element={<AboutUsProfile />} />

          <Route exact path="/about-us-mission" element={<AboutUsMission />} />

          <Route
            exact
            path="/about-us-objectives"
            element={<AboutUsObjectives />}
          />

          <Route
            exact
            path="/about-us-functions"
            element={<AboutUsFunctions />}
          />

          <Route exact path="/about-us-awards" element={<AboutUsAwards />} />

          <Route
            exact
            path="/about-us-board-of-directors"
            element={<AboutUsBOD />}
          />

          <Route
            exact
            path="/about-us-smu-executives"
            element={<AboutUsExecutives />}
          />

          <Route
            exact
            path="/about-us-organisation-chart"
            element={<AboutUsOrgChart />}
          />

          <Route
            exact
            path="/about-us-milestones"
            element={<AboutUsMilestones />}
          />
        </Route>

        {/* Routes requiring Authentication*/}
        {/* <Route element={<PersistLogin />}> */}
        <Route element={<DevLogin />}>
          <Route exact path="/login" element={<Login />} />
          {/* <Route
            element={<RequireAuth allowedRoles={allowedRoles} />}
          > */}

          <Route
            exact
            path={`/${userRolePrefix}/dashboard`}
            element={<SystemAdminDashboard />}
          />
          <Route
            exact
            path="/sysadmin/notice-config"
            element={<NoticeConfig />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/awards-configuration`}
            element={<AwardsConfig sidebarType={sidebarType} />}
          />
          <Route
            exact
            path={`/${userRolePrefix}/products-configuration`}
            element={<ProductConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/board-of-directors-configuration`}
            element={<BODConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/smu-executives-configuration`}
            element={<SMUExecutivesConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/milestones-configuration`}
            element={<MilestoneConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/events-configuration`}
            element={<EventConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/youtube-gallery-configuration`}
            element={<YoutubeGalleryConfig sidebarType={sidebarType} />}
          />

          <Route
            exact
            path={`/${userRolePrefix}/gallery-configuration`}
            element={<GalleryConfig sidebarType={sidebarType} />}
          />
        </Route>
        {/* </Route> */}
        {/* </Route> */}
      </Routes>
    </>
  );
}

export default App;
