import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "../features/login/loginSlice";
import authProviderReducer from "../features/authProvider/authProviderSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    authProvider: authProviderReducer,
  },
});
