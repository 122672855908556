import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../api/axios";
import { YOUTUBE_GALLERY_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AddMilestoneForm = ({
  getYoutubeGalleryCompleteDetailsList,
  editYoutubeGalleryDetails,
  setEditYoutubeGalleryDetails,
  setShowAddForm,
  setIsAdd,
}) => {
  const isEdit = Object.keys(editYoutubeGalleryDetails)?.length > 0;

  const defaultValues = {
    video_id: !isEdit ? "" : editYoutubeGalleryDetails?.video_id,
    video_title: !isEdit ? "" : editYoutubeGalleryDetails?.video_title,
    youtube_video_link: !isEdit
      ? ""
      : editYoutubeGalleryDetails?.youtube_video_link,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      let sendDataObj = {
        video_title: data.video_title,
        youtube_video_link: data.youtube_video_link,
      };

      let response = "";
      if (!isEdit) {
        sendDataObj.createdby = "admin";
        response = await axios.post(
          YOUTUBE_GALLERY_DETAILS_CONFIG_URL,
          sendDataObj
        );
      } else {
        sendDataObj.updatedby = "admin";
        sendDataObj.video_id = editYoutubeGalleryDetails?.video_id;

        response = await axios.post(
          `${YOUTUBE_GALLERY_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditYoutubeGalleryDetails({});
          console.log(
            "Youtube Gallery details have been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "Youtube Gallery details have been added successfully.",
            "success"
          );
        }
        getYoutubeGalleryCompleteDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Youtube Gallery Details Error:", response.data);
        } else {
          if (
            response.data.saveYoutubeGalleryDetails.includes(
              "unique_youtube_gallery"
            )
          ) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="video_title"
          register={register}
          name="Video Title"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter video title"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.video_title}
          setValue={setValue}
        />

        <Input
          defaultName="youtube_video_link"
          register={register}
          name="Youtube Video Link"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter link for the youtube video"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.youtube_video_link}
          setValue={setValue}
        />

        <div className="flex justify-between mt-10">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditYoutubeGalleryDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel Youtube Gallery Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddMilestoneForm;
