const reContact = /^[6-9]\d{9}$/;

const reEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const reIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;

const pinCode = /^[1-9][0-9]{5}$/;

const numberOnly = /^[0-9]+$/;

const fileNameExtension =
  /^[a-zA-Z0-9 _]{0,20}[a-zA-Z0-9_]{0,20}[/.]+[a-zA-Z]+$/;

const NonEmptyString = /.*\S.*/;

module.exports = {
  reContact,
  reEmail,
  reIFSC,
  pinCode,
  numberOnly,
  fileNameExtension,
  NonEmptyString,
};
