import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {},
  persist: false,
};

export const authProviderSlice = createSlice({
  name: "authProvider",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },

    setPersist: (state) => {
      state.persist = true;
    },

    resetAllAuthVariables: (state) => {
      state.auth = {};
      state.persist = false;
    },
  },
});

export const { setAuth, setPersist, resetAllAuthVariables } =
  authProviderSlice.actions;

export default authProviderSlice.reducer;
