import React, { useState } from "react";

import { PRODUCT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";

import CMSProductCard from "../../../reusable-components/cms-product-card/cmsProductCard.component";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function ProductList({
  productCompleteDetailsList,
  getProductCompleteDetailsList,
  setEditProductDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ productCompleteDetailsList });

  const [showDelete, setShowDelete] = useState(false);
  const [productDeleteId, setProductDeleteId] = useState(null);

  const onClickEdit = (product) => {
    // console.log("product received inside Edit: ", product);
    setEditProductDetails(product);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (product) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", productDeleteId);
    try {
      let response = "";
      if (productDeleteId) {
        response = await axios.post(`${PRODUCT_DETAILS_CONFIG_URL}/delete`, {
          product_id: productDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log("Product has been deleted successfully.", "success");
        getProductCompleteDetailsList();
      } else {
        console.log("Product deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setProductDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        Products Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10 mr-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add Product</div>
          </div>
        </div>
      </div>

      <div>
        {productCompleteDetailsList?.length > 0 ? (
          <div className="grid grid-cols-3 gap-y-10">
            {productCompleteDetailsList?.map((productObj) => (
              <div>
                <CMSProductCard
                  productObj={productObj}
                  onClickEdit={onClickEdit}
                  setProductDeleteId={setProductDeleteId}
                  setShowDelete={setShowDelete}
                  onClickDelete={onClickDelete}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-20">
            No products configured in the system.
          </div>
        )}
      </div>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This product will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </>
  );
}
