import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import axios from "../../../api/axios";
import { BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import showToast from "../../../utilities/notification/NotificationModal";

import AddBODForm from "./addBODForm.component";
import BODList from "./bodList.component";
import Dashboard from "../../dashboard-components/dashboard.component";

const BODConfig = () => {
  const [bodCompleteDetailsList, setBodCompleteDetailsList] = useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editBodDetails, setEditBodDetails] = useState({});

  const isEdit = Object.keys(editBodDetails).length > 0 ? true : false;

  const getBodCompleteDetailsList = async () => {
    try {
      const response = await axios.get(BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL);
      // console.log("BOD with complete details List", { response });
      response.status === 200 &&
        setBodCompleteDetailsList(response.data.bodCompleteDetails);
      response.status === 202 &&
        console.log("No Board Of Directors found in the system.", "error");
    } catch (error) {
      console.error("getBodCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBodCompleteDetailsList();
      setEditBodDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={"Super Admin"}>
        <ToastContainer />
        {showAddForm ? (
          <div className="flex flex-col mb-10">
            <div className="flex gap-2 items-center pb-5">
              {/* <div className="">
                {isAdd ? (
                  <MdAddCircleOutline size={18} color="green" />
                ) : (
                  <RxUpdate size={17} color="blue" />
                )}
              </div> */}
              <div className="text-primary font-semibold text-lg border-b border-primary">
                {isAdd ? (
                  <>Add Board Of Director</>
                ) : (
                  <>Update Board Of Director Details</>
                )}
              </div>
            </div>

            <AddBODForm
              getBodCompleteDetailsList={getBodCompleteDetailsList}
              editBodDetails={editBodDetails}
              setEditBodDetails={setEditBodDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <BODList
              bodCompleteDetailsList={bodCompleteDetailsList}
              getBodCompleteDetailsList={getBodCompleteDetailsList}
              setEditBodDetails={setEditBodDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default BODConfig;
