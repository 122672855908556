import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import headofficebannerimage from "../../../assets/headoffice.jpg";

const FacilitiesHeadOffice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={headofficebannerimage}
            className="object-fill w-full h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Head Office
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">Facilities</div>
          <div className="flex flex-col border border-gray-300">
            <Link to="/facilities-head-office">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Head Office
              </div>
            </Link>
            <Link to="/facilities-regional-office">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Regional Office
              </div>
            </Link>
            <Link to="/facilities-plant">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Plant
              </div>
            </Link>
            <Link to="/facilities-dairy-and-co-operative-societies">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Dairy and Co-operative Societies
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilitiesHeadOffice;
