import React from "react";
import { back_btn } from "../../theme/lightTheme";
import { MdOutlineArrowBackIos } from "react-icons/md";

export default function BackButton({ label, onclick }) {
  return (
    <button onClick={onclick} className={back_btn}>
      <MdOutlineArrowBackIos size={14} color="#1466A2" />
      <span className="ml-1  text-[#1466A2] ">{label}</span>
    </button>
  );
}
