import React, { useState } from "react";

import { SMU_EXECUTIVES_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";

import CMSExecutiveCard from "../../../reusable-components/cms-smu-executives-card/cmsExecutiveCard.component";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function BODList({
  smuExecutivesCompleteDetailsList,
  getSmuExecutivesCompleteDetailsList,
  setEditSmuExecutivesDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ smuExecutivesCompleteDetailsList });

  const [showDelete, setShowDelete] = useState(false);
  const [smuExecutiveDeleteId, setSmuExecutiveDeleteId] = useState(null);

  const onClickEdit = (executive) => {
    // console.log("executive received inside Edit: ", executive);
    setEditSmuExecutivesDetails(executive);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (executive) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", smuExecutiveDeleteId);
    try {
      let response = "";
      if (smuExecutiveDeleteId) {
        response = await axios.post(
          `${SMU_EXECUTIVES_DETAILS_CONFIG_URL}/delete`,
          {
            executive_id: smuExecutiveDeleteId,
          }
        );
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log(
          "Selected Executive has been deleted successfully.",
          "success"
        );
        getSmuExecutivesCompleteDetailsList();
      } else {
        console.log("Selected Executive deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setSmuExecutiveDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        SMU Executives Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10 mr-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add SMU Executive</div>
          </div>
        </div>
      </div>

      <div>
        {smuExecutivesCompleteDetailsList?.length > 0 ? (
          <div className="grid grid-cols-3 gap-y-10">
            {smuExecutivesCompleteDetailsList?.map((executiveObj) => (
              <div>
                <CMSExecutiveCard
                  executiveObj={executiveObj}
                  onClickEdit={onClickEdit}
                  setSmuExecutiveDeleteId={setSmuExecutiveDeleteId}
                  setShowDelete={setShowDelete}
                  onClickDelete={onClickDelete}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-20">
            No SMU executives configured in the system.
          </div>
        )}
      </div>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The selected executive will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </>
  );
}
